import { ReactElement, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useApplicationContext } from '../../contexts/ApplicationContext';
import { useLocation } from 'react-router-dom';
import { PageSetting } from '../../api/graphql/queries/getAppSettings';
import LanguageSelector from '../LanguageSelector/LanguageSelector';
import { useAppState } from '../app/useAppState';
import { getErrorMessage } from '../../helpers/errorMessage';

const ALL = 'All';
export interface IKillSwitch {
  pageName?: string;
  children?: any;
}

const DisabledContent = (props: { pageName?: string }): ReactElement => {
  const { t } = useTranslation(`killSwitch-${props.pageName ? props.pageName : ''}`);

  const { currentPartner } = useApplicationContext();
  const { changeLanguage } = useAppState();

  const _onLanguageChange = (language: string) => {
    changeLanguage(language);
  };

  return (
    <Container>
      <Row>
        <Col className="text-right">
          <LanguageSelector partner={currentPartner} onLanguageChange={_onLanguageChange} />
        </Col>
      </Row>
      <Row>
        <Col data-testid="kill-switch-container" className="error_container">
          <p data-testid="kill-switch-title" className="heading_super">
            {t('subHeading')}
          </p>
          <h1 data-testid="kill-switch-heading">{t('heading')}</h1>
          <p data-testid="kill-switch-message">{t('message')}</p>
        </Col>
      </Row>
    </Container>
  );
};

const KillSwitchContainer = (props: IKillSwitch): ReactElement => {
  const { currentAppSettings } = useApplicationContext();
  const [disabled, setDisabled] = useState(false);
  const [pageName, setPageName] = useState('');

  const location = useLocation();

  useEffect(() => {
    let pageSettingFound: Partial<PageSetting> | undefined;
    const pathName = props.pageName ?? location.pathname;

    try {
      if (currentAppSettings.appSettings.pageSettings && currentAppSettings.appSettings.pageSettings.length > 0) {
        pageSettingFound = currentAppSettings.appSettings.pageSettings.find(
          (pageSetting) =>
            pageSetting.page &&
            (isCurrentPageDisabled(pathName, pageSetting.page) || isDisabledForAll(pageSetting.page)),
        );

        if (pageSettingFound) {
          setDisabled(true);
          setPageName(pageSettingFound.page!.toLowerCase());
        }
      }
    } catch (e) {
      throw Error(`Error in Killswitch component: ${getErrorMessage(e).message}`);
    }
  }, [currentAppSettings, location.pathname, props.pageName]);

  return disabled ? <DisabledContent pageName={pageName} /> : (props.children as ReactElement);

  function isCurrentPageDisabled(pathName: string, pageName: string): boolean {
    return pathName.toLowerCase().endsWith(pageName.toLowerCase());
  }

  function isDisabledForAll(pageName: string): boolean {
    return pageName.toLowerCase() === ALL.toLowerCase();
  }
};

export default KillSwitchContainer;
