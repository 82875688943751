import Analytics from '../../analytics/Analytics';
import { FlowOptions, FlowStepActionFunctionVariadic } from '../flow-engine';
import history from '../../routing/BrowserHistory';
import { Persistence } from '../../persistence/Persistence';
import { APIContextActions } from '../../api/APIContext';

export const action: FlowStepActionFunctionVariadic = function action(
  _apiActions: APIContextActions,
  flowOptions: Partial<FlowOptions>,
): void {
  const { currentPartner, currentPartnerRegion, suggestedAuthProvider } = flowOptions;

  const traits =
    currentPartner && currentPartnerRegion && suggestedAuthProvider
      ? {
          partner: currentPartner,
          partnerRegion: currentPartnerRegion,
          authProvider: suggestedAuthProvider,
        }
      : undefined;
  Analytics.identify(traits);

  const path = Persistence.getLastLocation()!;
  Persistence.setLastLocation('none');
  Persistence.setShouldAuthenticateAndRedirectToLastLocation(false);
  history.replace(path);
};
