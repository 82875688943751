import { lazy, ReactElement } from 'react';
import LandingRedirector from './LandingRedirector';
import PrivateRoute from './PrivateRoute';
import { IAppRouterProps } from './RouterComponentSwitcher';

/* istanbul ignore next: No need to codecov this import */
const RegistrationView = lazy(() => import('../views/registration/Registration'));
/* istanbul ignore next: No need to codecov this import */
const HomeView = lazy(() => import('../views/home/Home'));
/* istanbul ignore next: No need to codecov this import */
const SuccessView = lazy(() => import('../views/success/Success'));
/* istanbul ignore next: No need to codecov this import */
const WelcomeView = lazy(() => import('../views/welcome/Welcome'));
/* istanbul ignore next: No need to codecov this import */
const ConsentValidationView = lazy(() => import('../views/consent-validation/ConsentValidation'));
/* istanbul ignore next: No need to codecov this test import */
const ConsentContainer = lazy(() => import('../views/consent/ConsentContainer'));
/* istanbul ignore next: No need to codecov this import */
const FAQ = lazy(() => import('../views/faq/FAQ'));
/* istanbul ignore next: No need to codecov this import */
const EndOfRedemptionPeriodView = lazy(() => import('../views/end-of-redemption-period/EndOfRedemptionPeriod'));

export const PrivateRoutes = (props: IAppRouterProps): ReactElement[] => {
  let privateRoutes: ReactElement[] = [];

  privateRoutes = [
    <PrivateRoute key="consent" exact={true} path="/consent">
      <ConsentContainer onErrorCallback={props.onErrorCallback} />
    </PrivateRoute>,
    <PrivateRoute key="welcome" exact={true} path="/welcome">
      <WelcomeView />
    </PrivateRoute>,
    <PrivateRoute key="prime-consent" exact={true} path="/prime-consent">
      <ConsentValidationView />
    </PrivateRoute>,
    <PrivateRoute key="home" exact={true} path="/home">
      <HomeView />
    </PrivateRoute>,
    <PrivateRoute key="success" exact={true} path="/success">
      <SuccessView />
    </PrivateRoute>,
    <PrivateRoute key="link-your-card" exact={true} path="/link-your-card">
      <RegistrationView />
    </PrivateRoute>,
    <PrivateRoute key="passcode-verify" exact={true} path="/passcode-verify">
      <RegistrationView />
    </PrivateRoute>,
    <PrivateRoute key="landing" exact={true} path="/landing">
      <LandingRedirector />
    </PrivateRoute>,
    <PrivateRoute key="end-of-redemption-period" exact={true} path="/end-of-redemption-period">
      <EndOfRedemptionPeriodView />
    </PrivateRoute>,
  ];
  props.partnerRegionRouteConfigs.forEach((routeConfig) => {
    privateRoutes.push(
      <PrivateRoute
        key={`/${routeConfig.partner}/:${routeConfig.partnerRegion}/faq`}
        path={`/${routeConfig.partner}/${routeConfig.partnerRegion}/faq`}
        component={FAQ}
        exact={true}
      />,
    );
  });

  return privateRoutes;
};
