import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translations from './translations';
import { isDev } from '../helpers/EnvironmentDetect';
import { Persistence } from '../persistence/Persistence';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: {
      order: ['localStorage'],
      lookupLocalStorage: Persistence.MARVEL_SETTINGS_LANGUAGE_KEY,
      caches: ['localStorage'],
    },
    resources: translations,
    keySeparator: false,
    fallbackLng: 'en',
    supportedLngs: ['nl', 'en', 'es'],
    debug: isDev,
  });

export default i18n;
