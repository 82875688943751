import { ReactElement, ReactNode, useEffect } from 'react';
import { Modal as BsModal } from 'react-bootstrap';
import { showErrorModalAtom } from '../../store';
import styles from './ErrorModal.module.scss';
import { useAtomValue } from 'jotai/react';
import { useTranslation } from 'react-i18next';

interface Props {
  show?: boolean;
  backdrop?: boolean;
  title?: string;
  button?: string;
  onRefresh: () => void;
  children?: ReactNode;
}

export function refreshPage() {
  window.location.reload();
}

function ErrorModal({ backdrop, title, button, onRefresh, children }: Props): ReactElement {
  const { t } = useTranslation('errorModal');
  const titleText = title ? title : t('title');
  const buttonText = button ? button : t('button');
  const descriptionText = children ? children : t('description');
  function handleRefresh() {
    if (onRefresh) {
      onRefresh();
      return;
    }
    window.location.reload();
  }

  const showErrorModal = useAtomValue(showErrorModalAtom);

  useEffect(() => {
    const htmlElement: null | HTMLElement = document.querySelector('html');
    if (!htmlElement) {
      return;
    }
    htmlElement.style.overflow = showErrorModal ? 'hidden' : 'visible';
  }, [showErrorModal]);

  return (
    <BsModal className={styles.modal} show={showErrorModal} backdrop={backdrop} centered>
      <div className={styles.container}>
        <BsModal.Header>{titleText}</BsModal.Header>
        <BsModal.Body>
          <div className={styles.bodycontainer}>{descriptionText}</div>
        </BsModal.Body>
      </div>

      <BsModal.Footer>
        <button type="button" onClick={handleRefresh} className={styles.button}>
          {buttonText}
        </button>
      </BsModal.Footer>
    </BsModal>
  );
}

export default ErrorModal;
