import { CipUserDto, IUser } from '../user/IUser';
import { IUserPii } from '../user/IUserPii';
import { getFirstNameLastName } from './getFirstNameLastName';

export function mapCipUser(userPii: IUserPii = {}, user: IUser = {}, cipUserInfo: CipUserDto): IUser {
  const { firstName, lastName } = getFirstNameLastName(userPii);

  return {
    userId: cipUserInfo.external_id,
    attributes: {
      email: userPii.email,
      phone: userPii.phone,
    },
    firstName,
    lastName,
    consents: userPii.consents,
    phoneNumberVerified: !!cipUserInfo.phone_number_verified,
    phone: cipUserInfo.phone_number,
    loyaltyInfo: user.loyaltyInfo,
    partnerInfo: user.partnerInfo,
  };
}

export function validOrEmptyPhoneByRegion(region?: string, phone?: string) {
  const phoneCodes: Record<string, string> = {
    us: '+1',
    au: '+61',
    uk: '+44',
  };
  if (!region || !phone) {
    return true;
  }

  return !phoneCodes[region] || phone.indexOf(phoneCodes[region]) === 0;
}
