import { RouteConfig } from '../../routing/RouterComponentSwitcher';
import { staticConfig } from '../../config';

const PartnerRegionRouteConfigs: RouteConfig[] = [];

staticConfig.partners.forEach((partner) => {
  partner.regions.forEach((region) => {
    const routeConfig = {
      partner: partner.partnerType,
      partnerRegion: region.regionName,
    };
    PartnerRegionRouteConfigs.push(routeConfig!);
  });
});

export default PartnerRegionRouteConfigs;
