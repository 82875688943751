import { ReactElement } from 'react';
import { useTheme } from '../../utils/theme/useTheme';
import { useApplicationContext } from '../../contexts/ApplicationContext';
import { getSupportedLanguages } from '../../helpers/PartnerRegionPropertySelector';
import { useAppState } from '../app/useAppState';
import history from '../../routing/BrowserHistory';

import { useAtomValue } from 'jotai';
import { programAtom } from '../../store';
import { useShowBackButton } from './useShowBackButton';

import styles from './Header.module.scss';
import UberProHeader from './helper/uberHeader';
import PrimeHeader from './helper/primeHeader';
import { useWindowLocation } from '../../hooks/useWindowLocation';
import { getPartnerAndRegion } from './helper/utils';
import DefaultHeader from './helper/defaultHeader';

interface Props {
  backButton?: boolean;
  onBack?: () => void;
}
const Header = ({ backButton, onBack }: Props): ReactElement => {
  const program = useAtomValue(programAtom);
  const theme = useTheme();
  const showBackButton = useShowBackButton();
  const { currentPartner, currentPartnerRegion } = useApplicationContext();
  const languages = getSupportedLanguages(currentPartner, currentPartnerRegion);
  const { changeLanguage } = useAppState();
  const location = useWindowLocation();

  function handleBack() {
    if (onBack) {
      onBack();
      return;
    }
    history.goBack();
  }

  if (!theme) {
    return <div data-testid="header" className={styles.header}></div>;
  }

  let { partner, region }: any = getPartnerAndRegion(location);

  partner = partner ?? currentPartner;
  region = region ?? currentPartnerRegion;

  const getHeaderByPartner = () => {
    switch (partner) {
      case 'uber-pro':
        return (
          <UberProHeader
            backButton={backButton}
            showBackButton={showBackButton}
            currentPartnerRegion={region}
            currentPartner={partner}
            program={program}
            languages={languages}
            handleBack={handleBack}
            changeLanguage={changeLanguage}
          />
        );
      case 'amazon-prime':
        return <PrimeHeader />;
      default:
        return <DefaultHeader />;
    }
  };

  return <>{getHeaderByPartner()}</>;
};

export default Header;
