import { Persistence } from '../../persistence/Persistence';
import { FlowStepRuleFunctionVariadic } from '../flow-engine';

export const rule: FlowStepRuleFunctionVariadic = async function rule(_apiContext, flowOptions) {
  const { user } = flowOptions;

  const loyaltyId = user?.loyaltyInfo?.loyaltyId || Persistence.getLoyaltyId();

  // If the loyalty card number is in persistence...
  return !!loyaltyId;
};
