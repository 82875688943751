import { useMemo, ReactElement, createContext, useContext } from 'react';
import { AuthProvider } from '@marvel-common/ridehailing-idp';

import { AppSyncClient, IBFFClientResult } from './graphql/AppSyncClient';

import patchUserMutation, { UserInput } from './graphql/mutations/patchUser';
import getUserQuery, {
  IGetUserResult,
  getLoyaltyInfoQuery,
  getPartnerStatusQuery,
  getUserCipQuery,
} from './graphql/queries/getUser';
import saveUserV2CIPMutation, { TokenInput } from './graphql/mutations/saveUserV2CIP';
import saveUserV2SalesforceMutation from './graphql/mutations/saveUserV2Salesforce';
import validatePhoneCIPMutation, { PhoneInput } from './graphql/mutations/validatePhoneCIP';
import linkLoyaltyInfoMutation, { LinkLoyaltyInfoInput } from './graphql/mutations/linkLoyaltyInfo';
import validatePhonePasscodeMutationCIP from './graphql/mutations/validatePhonePasscodeCIP';

import getOffersQuery, { IOffersResult } from './graphql/queries/getOffers';
import getAppSettingsQuery, { ISettingsResult } from './graphql/queries/getAppSettings';
import getLoyaltyBalanceQuery, { IGetLoyaltyBalanceResult } from './graphql/queries/getLoyaltyBalanceQuery';
import patchMappingsMutation, { PatchMappingsInput } from './graphql/mutations/patchMappings';

import { useSuggestedAuthProvider } from '../hooks/useSuggestedAuthProvider';

import { staticConfig } from '../config';
import { LoyaltyInfo } from '../user/IUser';
import { timeout } from '../utils/timeout';
import { isDecodableJWT, isTokenExpired } from '../helpers/JWT';
import partnerLinkingMutation, { PartnerInformation } from './graphql/mutations/partnerLinking';

export interface APIContextActionsAppSyncConfig {
  endpoint: string;
  region: string;
  apiKey: string;
}

export class APIContextActions {
  provider: AuthProvider;

  private endpoint: string;
  private region: string;
  private apiKey: string;

  constructor(provider: AuthProvider, config: APIContextActionsAppSyncConfig) {
    this.provider = provider;
    this.endpoint = config.endpoint;
    this.region = config.region;
    this.apiKey = config.apiKey;
  }

  updateConsent = async (
    idToken: string,
    termsAcceptedVersion: string,
    privacyAcceptedVersion: string,
    marketingChannels: string[],
    partnerType: string,
    partnerRegion: string,
    isConsentUpdate: boolean,
    accessToken?: string,
  ): Promise<IBFFClientResult> => {
    const userInput: UserInput = {
      consentInput: {
        partnerType,
        region: partnerRegion,
        privacyVersion: privacyAcceptedVersion,
        termsVersion: termsAcceptedVersion,
        marketingChannels,
        isConsentUpdate,
      },
      accessToken,
    };
    const apiClient = new AppSyncClient({ idToken, url: this.endpoint, region: this.region });
    return apiClient.post(patchUserMutation, { userInput });
  };

  linkLoyaltyInfo = async (idToken: string, linkLoyaltyInfoInput: LinkLoyaltyInfoInput): Promise<IBFFClientResult> => {
    const apiClient = new AppSyncClient({ idToken, url: this.endpoint, region: this.region });
    const res = await apiClient.post(linkLoyaltyInfoMutation, { linkLoyaltyInfoInput });
    return res;
  };

  patchMappings = async (idToken: string, patchMappingsInput: PatchMappingsInput): Promise<IBFFClientResult> => {
    const apiClient = new AppSyncClient({ idToken, url: this.endpoint, region: this.region });
    const result = await apiClient.post(patchMappingsMutation, { patchMappingsInput });
    return result;
  };

  getUser = async (
    idToken: string,
    partnerType: string,
    loyaltyType: string,
    partnerRegion: string,
    userId: string,
    accessToken: string,
  ): Promise<IGetUserResult> => {
    const apiClient = new AppSyncClient({ idToken, url: this.endpoint, region: this.region });
    return apiClient.fetch(getUserQuery, {
      partnerType,
      loyaltyType,
      partnerRegion,
      userId,
      accessToken,
    });
  };

  getPartnerStatus = async ({ idToken, partnerType, userId, region }: any): Promise<any> => {
    const apiClient = new AppSyncClient({ idToken, url: this.endpoint, region: this.region });
    const partnerStatusInformation: any = {
      userId,
      partnerType,
      partnerRegion: region,
    };
    return apiClient.fetch(getPartnerStatusQuery, { partnerStatusInformation });
  };

  setPartnerLinking = async (idToken: string, partnerInformation: PartnerInformation): Promise<IBFFClientResult> => {
    const apiClient = new AppSyncClient({ idToken, url: this.endpoint, region: this.region });
    return apiClient.post(partnerLinkingMutation, { partnerInformation });
  };

  getCipUser = async (
    idToken: string,
    partnerType: string,
    loyaltyType: string,
    partnerRegion: string,
    userId: string,
    accessToken: string,
  ): Promise<IGetUserResult> => {
    const apiClient = new AppSyncClient({ idToken, url: this.endpoint, region: this.region });
    return apiClient.fetch(getUserCipQuery, {
      partnerType,
      loyaltyType,
      partnerRegion,
      userId,
      accessToken,
    });
  };

  pollLoyaltyInfo = async (
    idToken: string,
    partnerType: string,
    loyaltyType: string,
    partnerRegion: string,
    retry = 0,
  ): Promise<LoyaltyInfo | undefined> => {
    const [loyaltyInfo, err] = await this.getLoyaltyInfo(idToken, partnerType, loyaltyType, partnerRegion);

    if (err) {
      return;
    }

    if (loyaltyInfo?.loyaltyId) {
      return loyaltyInfo;
    }

    if (retry > 20) {
      return;
    }

    await timeout(1000);
    return this.pollLoyaltyInfo(idToken, partnerType, loyaltyType, partnerRegion, retry + 1);
  };

  getLoyaltyInfo = async (
    idToken: string,
    partnerType: string,
    loyaltyType: string,
    partnerRegion: string,
  ): Promise<[LoyaltyInfo | undefined, unknown | undefined]> => {
    try {
      const apiClient = new AppSyncClient({ idToken, url: this.endpoint, region: this.region });
      const res = await apiClient.fetch<{ user: { loyaltyInfo: LoyaltyInfo } }>(getLoyaltyInfoQuery, {
        partnerType,
        loyaltyType,
        partnerRegion,
      });
      return [res.user?.loyaltyInfo, undefined];
    } catch (e) {
      return [undefined, e];
    }
  };

  getLoyaltyBalance = async (
    idToken: string,
    partnerType: string,
    loyaltyType: string,
    partnerRegion: string,
  ): Promise<IGetLoyaltyBalanceResult> => {
    const apiClient = new AppSyncClient({ idToken, url: this.endpoint, region: this.region });
    return apiClient.fetch(getLoyaltyBalanceQuery, {
      partnerType,
      loyaltyType,
      partnerRegion,
    });
  };

  getOffers = async (idToken: string, partnerType: string, partnerRegion: string): Promise<IOffersResult> => {
    const apiClient = new AppSyncClient({ idToken, url: this.endpoint, region: this.region });

    return apiClient.fetch(getOffersQuery, { partnerType: partnerType, partnerRegion: partnerRegion });
  };

  getAppSettings = async (settingKey: string, partnerType: string, partnerRegion: string): Promise<ISettingsResult> => {
    const apiClient = new AppSyncClient({ apiKey: this.apiKey, url: this.endpoint, region: this.region });

    return apiClient.fetch(getAppSettingsQuery, {
      settingKey: settingKey,
      partnerType: partnerType,
      partnerRegion: partnerRegion,
    });
  };

  saveUserTokensV2 = async (
    idToken: string,
    refreshToken: string,
    accessToken: string,
    uberRefreshToken?: string,
  ): Promise<IBFFClientResult> => {
    if (!idToken || !isDecodableJWT(idToken) || isTokenExpired(idToken)) {
      throw Error('id token is null or corrupt or expired');
    }

    const apiClient = new AppSyncClient({ idToken, url: this.endpoint, region: this.region });
    const tokenInput: TokenInput = {
      refreshToken: refreshToken ?? '',
      accessToken: accessToken,
      uberRefreshToken,
    };
    return apiClient.post(saveUserV2CIPMutation, { tokenInput });
  };

  saveUserTokens = async (idToken: string, refreshToken: string, accessToken: string): Promise<IBFFClientResult> => {
    if (!idToken || !isDecodableJWT(idToken) || isTokenExpired(idToken)) {
      throw Error('id token is null or corrupt or expired');
    }

    const apiClient = new AppSyncClient({ idToken, url: this.endpoint, region: this.region });
    return apiClient.post(saveUserV2SalesforceMutation, {
      refreshToken: refreshToken,
      accessToken: accessToken,
    });
  };

  validatePhone = async (idToken: string, phoneInput: PhoneInput): Promise<IBFFClientResult> => {
    const apiClient = new AppSyncClient({ idToken, url: this.endpoint, region: this.region });
    return apiClient.post(validatePhoneCIPMutation, { phoneInput });
  };

  validatePhonePasscode = async (
    idToken: string,
    phoneInput: PhoneInput,
    passcode: string,
    passcodeSessionId?: string,
  ): Promise<IBFFClientResult> => {
    const apiClient = new AppSyncClient({ idToken, url: this.endpoint, region: this.region });
    return apiClient.post(validatePhonePasscodeMutationCIP, { phoneInput, passcode, passcodeSessionId });
  };
}

const initialState: APIContextActions = new APIContextActions('cip', staticConfig.appsyncV2);

export interface APIContextProviderOptions {
  children?: ReactElement;
}

const APIContext = createContext<APIContextActions>(initialState);

const useAPIContext = (): APIContextActions => useContext(APIContext);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const APIContextProvider = ({ children }: APIContextProviderOptions): JSX.Element => {
  const provider = useSuggestedAuthProvider();

  const value = useMemo(() => {
    return new APIContextActions(provider, staticConfig.appsyncV2);
  }, [provider]);

  return <APIContext.Provider value={value}>{children}</APIContext.Provider>;
};

export { APIContextProvider, useAPIContext, APIContext };
