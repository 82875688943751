import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

import { ProgramType } from './interfaces/ProgramType';
import { Persistence } from './persistence/Persistence';
import { ILoyaltyBalance } from './user/ILoyaltyBalance';
import { ILoyaltyInfo } from './user/ILoyaltyInfo';

export const showFooterAtom = atom<boolean>(true);
export const embeddedAtom = atom<boolean>(false);

export const showErrorModalAtom = atom<boolean>(false);

export const programAtom = atomWithStorage<ProgramType>(Persistence.MARVEL_SETTINGS_PROGRAM_KEY, 'fuel');

export const loyaltyBalanceAtom = atom<ILoyaltyBalance>({
  loading: 'idle',
});

export const loyaltyInfoAtom = atom<ILoyaltyInfo>({
  loading: 'idle',
  loyaltyId: '',
  loyaltyBarcode: '',
});
