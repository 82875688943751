import { createContext, useContext, useReducer, useEffect } from 'react';
import { ISettingsProps, Persistence } from './Persistence';
import { ILoyaltyBalance } from '../user/ILoyaltyBalance';
import { ILoyaltyInfo } from '../user/ILoyaltyInfo';
import { useOAuthContext } from '../auth/OAuthContext';
import { IGetUserResult } from '../api/graphql/queries/getUser';
import { getMarvelLogger } from '@marvel-common/bp-digital-logging';
import { getFirstNameLastName } from '../utils/getFirstNameLastName';

enum persistenceTypes {
  SET_SETTINGS = 'SET_SETTINGS',
}

export interface ISettingsAction extends ISettingsProps {
  type: persistenceTypes;
}

export interface ISettingsState {
  partnerLevel: string;
  setPartnerLevel: (partnerLevel: string) => void;
  partnerLevelId: string;
  setPartnerLevelId: (partnerLevelId: string) => void;
  loyaltyInfo: ILoyaltyInfo;
  setLoyaltyInfo: (loyaltyInfo: ILoyaltyInfo) => void;
  loyaltyBalance: ILoyaltyBalance;
  setLoyaltyBalance: (loyaltyBalance: ILoyaltyBalance) => void;
  firstName: string;
  setFirstName: (firstName: string) => void;
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
  partnerTierOfferValue: number | null;
  setPartnerTierOfferValue: (value: number | null) => void;
  partnerPriceMatchOfferActive: boolean | null;
  setPartnerPriceMatchOfferActive: (value: boolean) => void;
  phoneNumberVerificationSessionIdState: string;
  setPhoneNumberVerificationSessionIdState: (value: string) => void;
  setUserData: (loyaltyInfo: IGetUserResult) => string;
}

const stringReplaceReducer = (_oldValue: string, newValue: string) => newValue;
const loyaltyBalanceReducer = (_oldValue: ILoyaltyBalance, newValue: ILoyaltyBalance) => newValue;
const LoyaltyInfoReducer = (_oldValue: ILoyaltyInfo, newValue: ILoyaltyInfo) => newValue;
const numberReplaceReducer = (_oldValue: number | null, newValue: number | null) => newValue;

/**
 * PersistenceContextProvider provides a global state for persistence using local storage
 *
 * Implement by wrapping your App component
 * ```
 * import { PersistenceContextProvider } from './persistence/PersistenceContext';
 *
 *  <PersistenceContextProvider>
 *       <App clients={clients} />
 *     </PersistenceContextProvider>
 *
 * ```
 *
 * Example usage from lower-order-component
 * ```
 * import { usePersistenceContext, persistenceTypes } from '../../persistence/PersistenceContext';
 * const Welcome = () => {
 * const { settings, dispatchPersistence } = usePersistenceContext();
 * const setLocale = useCallback(
 *   () => dispatchPersistence({ type: persistenceTypes.SET_SETTINGS, partner: 'uber', language: 'en', country: 'nl' }),
 *   [],
 * );
 *  return (
 *    <Container>
 *      <Row className="content p-3">
 *        <Col className="text-center ">[Welcome]</Col>
 *      </Row>
 *      <Row className="content p-3">
 *        <Col className="text-center ">{`${settings.partner}\\${settings.country}\\${settings.language}`}</Col>
 *      </Row>
 *      <Row className="content p-3">
 *        <Buttton onClick={() => setLocale()}>Change to locale</Buttton>
 *      </Row>
 *    </Container>
 *  );
 * };
 * ```
 */

/* istanbul ignore next: No need to codecov*/
const PersistenceContext = createContext<ISettingsState>({
  partnerLevel: '',
  /* istanbul ignore next: No need to codecov*/
  setPartnerLevel: (_partnerLevel: string) => {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      ('');
    }
  },
  partnerLevelId: '',
  /* istanbul ignore next: No need to codecov*/
  setPartnerLevelId: (_partnerLevelId: string) => {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      ('');
    }
  },
  loyaltyInfo: { loyaltyId: '', loading: 'idle', loyaltyBarcode: '' },
  /* istanbul ignore next: No need to codecov*/
  setLoyaltyInfo: (_loyaltyInfo: ILoyaltyInfo) => {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      ('');
    }
  },
  loyaltyBalance: { currentPointsBalance: 0, currencyValue: 0, currencySymbol: '', loading: 'idle' },
  /* istanbul ignore next: No need to codecov*/
  setLoyaltyBalance: (_loyaltyBalance: ILoyaltyBalance) => {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      ('');
    }
  },
  firstName: '',
  /* istanbul ignore next: No need to codecov*/
  setFirstName: (_firstName: string) => {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      ('');
    }
  },
  phoneNumber: '',
  /* istanbul ignore next: No need to codecov*/
  setPhoneNumber: (_phoneNumber: string) => {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      ('');
    }
  },
  partnerTierOfferValue: 0,
  /* istanbul ignore next: No need to codecov*/
  setPartnerTierOfferValue: (_value: number | null) => {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      ('');
    }
  },
  partnerPriceMatchOfferActive: false,
  /* istanbul ignore next: No need to codecov*/
  setPartnerPriceMatchOfferActive: (_value: boolean) => {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      false;
    }
  },
  setUserData: (_getUserResult: IGetUserResult): string => {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      ('');
    }
    return '';
  },
  phoneNumberVerificationSessionIdState: '',
  setPhoneNumberVerificationSessionIdState: (_value: string): string => {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      ('');
    }
    return '';
  },
});

const usePersistenceContext = (): ISettingsState => useContext(PersistenceContext);

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const PersistenceContextProvider = ({ children }: any): JSX.Element => {
  const { user } = useOAuthContext();

  const [loyaltyBalance, setLoyaltyBalance] = useReducer(loyaltyBalanceReducer, {
    currentPointsBalance: 0,
    currencyValue: 0,
    currencySymbol: '€',
    loading: 'loading',
  });

  let initialLoyaltyInfoState: ILoyaltyInfo;
  let initialPartnerLevelState = '';
  let initialPartnerLevelIdState = '';
  let initialFirstName = '';
  let initialPhoneNumberState = '';

  if (user?.userId) {
    initialLoyaltyInfoState = {
      loyaltyId: Persistence.getLoyaltyId() as string,
      loading: Persistence.getLoyaltyId() ? 'completed' : 'loading',
      loyaltyBarcode:
        user.loyaltyInfo?.loyaltyIdBarCode !== undefined && user.loyaltyInfo?.loyaltyIdBarCode !== null
          ? (Persistence.getLoyaltyBarcode() as string)
          : '',
    };
    initialPhoneNumberState = Persistence.getPhoneNumber() as string;
    initialPartnerLevelState = Persistence.getPartnerLevel() as string;
    initialPartnerLevelIdState = Persistence.getPartnerLevelId() as string;
    initialFirstName = Persistence.getFirstName() as string;
  } else {
    initialLoyaltyInfoState = {
      loyaltyId: '' as string,
      loading: 'loading',
      loyaltyBarcode: '' as string,
    };
  }

  const [phoneNumber, setPhoneNumber] = useReducer(stringReplaceReducer, initialPhoneNumberState);
  const [phoneNumberVerificationSessionIdState, setPhoneNumberVerificationSessionIdState] = useReducer(
    stringReplaceReducer,
    '',
  );
  const [firstName, setFirstName] = useReducer(stringReplaceReducer, initialFirstName);
  const [partnerLevel, setPartnerLevel] = useReducer(stringReplaceReducer, initialPartnerLevelState);
  const [partnerLevelId, setPartnerLevelId] = useReducer(stringReplaceReducer, initialPartnerLevelIdState);
  const [loyaltyInfo, setLoyaltyInfo] = useReducer(LoyaltyInfoReducer, initialLoyaltyInfoState);

  const [partnerTierOfferValue, setPartnerTierOfferValue] = useReducer(
    numberReplaceReducer,
    Persistence.getPartnerTierOfferValue() as number,
  );

  const [partnerPriceMatchOfferActive, setPartnerPriceMatchOfferActive] = useReducer(
    (_oldValue: boolean, newValue: boolean) => newValue,
    Persistence.getPartnerPriceMatchOfferActiveStatus() as boolean,
  );

  useEffect(() => {
    Persistence.setPartnerLevel(partnerLevel);
  }, [partnerLevel]);

  useEffect(() => {
    Persistence.setPartnerLevelId(partnerLevelId);
  }, [partnerLevelId]);

  useEffect(() => {
    Persistence.setPhoneNumber(phoneNumber);
  }, [phoneNumber]);

  useEffect(() => {
    Persistence.setFirstName(firstName);
  }, [firstName]);

  useEffect(() => {
    Persistence.setPartnerTierOfferValue(partnerTierOfferValue);
  }, [partnerTierOfferValue]);

  useEffect(() => {
    Persistence.setPartnerPriceMatchOffer(partnerPriceMatchOfferActive);
  }, [partnerPriceMatchOfferActive]);

  useEffect(() => {
    if (user && user.userId) {
      setLoyaltyInfo({
        loyaltyId: Persistence.getLoyaltyId() as string,
        loading: Persistence.getLoyaltyId() ? 'completed' : 'loading',
        loyaltyBarcode: user.loyaltyInfo?.loyaltyIdBarCode
          ? (Persistence.getLoyaltyBarcode() as string)
          : (Persistence.getLoyaltyId() as string),
      });
      setPhoneNumber(Persistence.getPhoneNumber() as string);
      setPartnerLevel(Persistence.getPartnerLevel() as string);
      setPartnerLevelId(Persistence.getPartnerLevelId() as string);
      setFirstName(Persistence.getFirstName() as string);
    }
  }, [user]);

  useEffect(() => {
    if (!loyaltyInfo || (loyaltyInfo.loading === 'completed' && !loyaltyInfo.loyaltyId)) {
      Persistence.removeLoyaltyId();
      if (!loyaltyInfo.loyaltyBarcode) {
        Persistence.removeLoyaltyBarcode();
      }
      return;
    }
    if (loyaltyInfo.loading === 'completed' && loyaltyInfo.loyaltyId) {
      Persistence.setLoyaltyId(loyaltyInfo.loyaltyId);
      if (loyaltyInfo.loyaltyBarcode) {
        Persistence.setLoyaltyBarcode(loyaltyInfo.loyaltyBarcode);
      }
    }
  }, [loyaltyInfo]);

  const setUserData = (userResult: IGetUserResult): string => {
    const logger = getMarvelLogger('setUserData');
    // save partner level
    const _partnerLevel: string = userResult!.user.partnerInfo?.partnerLevel as string;
    logger.info('PartnerLevel: ' + _partnerLevel);
    setPartnerLevel(_partnerLevel);

    // save partner level ID
    const _partnerLevelId: string = userResult!.user.partnerInfo?.partnerLevelId as string;
    logger.info('PartnerLevelID: ' + _partnerLevelId);
    setPartnerLevelId(_partnerLevelId);

    // save loyalty card
    const _loyaltyId = userResult!.user.loyaltyInfo?.loyaltyId as string;
    const _loyaltyBarcode = userResult!.user.loyaltyInfo?.loyaltyIdBarCode
      ? userResult!.user.loyaltyInfo?.loyaltyIdBarCode
      : _loyaltyId;
    logger.info('LoyaltyId: ' + _loyaltyId);
    setLoyaltyInfo({
      loyaltyId: _loyaltyId,
      loading: 'completed',
      loyaltyBarcode: _loyaltyBarcode,
    });

    const { firstName } = getFirstNameLastName(userResult?.userPii);
    setFirstName(`${firstName}`);

    const phone: string = userResult!.userPii.phone as string;
    // Get last 10 digits of phone number, because we don't need the country code
    setPhoneNumber(phone?.slice(-10));

    return _loyaltyId;
  };

  return (
    <PersistenceContext.Provider
      value={{
        setPartnerLevel,
        setPartnerLevelId,
        setLoyaltyInfo,
        setLoyaltyBalance,
        setFirstName,
        setPhoneNumber,
        setPartnerTierOfferValue,
        setPartnerPriceMatchOfferActive,
        setPhoneNumberVerificationSessionIdState,
        partnerLevel: partnerLevel,
        partnerLevelId: partnerLevelId,
        loyaltyInfo: loyaltyInfo,
        loyaltyBalance: loyaltyBalance,
        firstName: firstName,
        phoneNumber: phoneNumber,
        partnerTierOfferValue,
        phoneNumberVerificationSessionIdState,
        partnerPriceMatchOfferActive,
        setUserData,
      }}
    >
      {children}
    </PersistenceContext.Provider>
  );
};

export { persistenceTypes, PersistenceContextProvider, usePersistenceContext, PersistenceContext };
