import i18next from 'i18next';
import { useAtom } from 'jotai/react';

import { useApplicationContext } from '../../contexts/ApplicationContext';
import { embeddedAtom, showFooterAtom } from '../../store';
import { getMarvelLogger } from '@marvel-common/bp-digital-logging';

interface AppState {
  showFooter: boolean;
  setShowFooter(value: boolean): void;
  embedded: boolean;
  setEmbedded(value: boolean): void;
  changeLanguage(language: string): void;
}

export function useAppState(): AppState {
  const logger = getMarvelLogger('usePageState');
  const [showFooter, setShowFooter] = useAtom(showFooterAtom);
  const [embedded, setEmbedded] = useAtom(embeddedAtom);

  const { setCurrentLanguage } = useApplicationContext();

  // logger has a problem in storybook with some global files, this is one of them!
  // for now it is best to use the normal browser console logger instead for this file.
  async function changeLanguage(language: string) {
    setCurrentLanguage(language);
    await i18next.changeLanguage(language, (err) => {
      if (err) {
        logger.error('error setting language', err);
        logger.alert({ message: (err as Error).message, stack: err });
      }
    });
  }

  return {
    showFooter,
    setShowFooter,
    embedded,
    setEmbedded,
    changeLanguage,
  } as AppState;
}
