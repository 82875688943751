import { useCallback } from 'react';
import { useAPIContext } from '../api/APIContext';
import { IBFFClientResult, IGraphQLErrors } from '../api/graphql/AppSyncClient';
import history from '../routing/BrowserHistory';
import { useApplicationContext } from '../contexts/ApplicationContext';
import { useOAuthContext } from '../auth/OAuthContext';
import { usePersistenceContext } from '../persistence/PersistenceContext';
import { LinkLoyaltyInfoInput } from '../api/graphql/mutations/linkLoyaltyInfo';
import { ILoyaltyInfo } from '../user/ILoyaltyInfo';
import { redirectToPartnerPage } from '../helpers/CustomRedirector';

export interface CreateLoyaltyInfo {
  idToken: string;
  accessToken?: string;
  loyaltyId: string;
  userId: string;
  loyaltyType: string;
  partnerType: string;
  partnerLevelId: string;
  partnerRegion: string;
  linkLoyaltyInfo: (accessToken: string, input: LinkLoyaltyInfoInput) => Promise<IBFFClientResult>;
  pollLoyaltyInfo: any;
  fromRegister?: boolean;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
}

export interface PartnerLinkingObj {
  partnerType: string;
  partnerRegion: string;
  accessToken?: string;
  userId: string;
  fromRegister?: boolean;
}

export async function createLoyaltyWithPolling({
  idToken,
  accessToken,
  loyaltyId,
  loyaltyType,
  partnerType,
  userId,
  partnerLevelId,
  partnerRegion,
  linkLoyaltyInfo,
  pollLoyaltyInfo,
  fromRegister = false,
  firstName,
  lastName,
  email,
  phone = '',
}: CreateLoyaltyInfo): Promise<ILoyaltyInfo | undefined> {
  const partnerLinkingObj: PartnerLinkingObj = {
    partnerType,
    accessToken,
    partnerRegion,
    userId,
    fromRegister,
  };

  try {
    const linkLoyaltyInfoInput = {
      loyaltyId,
      loyaltyType,
      partnerType,
      partnerLevelId,
      partnerRegion,
      cipToken: partnerType === 'amazon-prime' ? accessToken : undefined,
      firstName,
      lastName,
      email,
      phone,
    };

    // Save Loyalty ID in persistence so that we're not accidentally forwarded to the
    // welcome page
    const loyaltyInfo = (await linkLoyaltyInfo!(idToken!, linkLoyaltyInfoInput)).data;
    return returnLoyaltyInfo(partnerLinkingObj, loyaltyInfo);
  } catch (err) {
    const graphQLError = err as IGraphQLErrors;

    const isTimeout = !!graphQLError.message && graphQLError.message.indexOf('Endpoint request timed out') !== -1;

    if (isTimeout) {
      // graphQLError.networkError.statusCode === 408
      const loyaltyInfo = await pollLoyaltyInfo(idToken!, partnerType, loyaltyType, partnerRegion);
      if (loyaltyInfo?.loyaltyId) {
        return returnLoyaltyInfo(partnerLinkingObj, loyaltyInfo);
      }
    }

    if (
      graphQLError.graphQLErrors &&
      graphQLError.graphQLErrors.length > 0 &&
      (graphQLError.message ===
        'GraphQL error: "The mobile phone number is already registered on BP Me Rewards(FIS)"' ||
        graphQLError.message === 'GraphQL error: "The SFID is already registered on BP Me Rewards(FIS)"')
    ) {
      history.push('/welcome?alreadyRegistered=true');
    } else {
      history.replace(`/500/${partnerType}/${partnerRegion}?${graphQLError.message}`);
    }
    return;
  }
}

const returnLoyaltyInfo = async (
  partnerLinkingObj: PartnerLinkingObj,
  loyaltyInfo: any,
): Promise<ILoyaltyInfo | undefined> => {
  try {
    if (!partnerLinkingObj?.fromRegister) {
      redirectToPartnerPage(partnerLinkingObj.partnerType, history);
    }
    const _loyaltyId = loyaltyInfo?.linkLoyaltyInfo?.loyaltyId as string;
    const _loyaltyBarcode = loyaltyInfo?.linkLoyaltyInfo?.loyaltyBarcode as string;
    return {
      loyaltyId: _loyaltyId,
      loading: 'completed',
      loyaltyBarcode: _loyaltyBarcode,
    };
  } catch (err) {
    const graphQLError = err as IGraphQLErrors;
    history.replace(`/500/${partnerLinkingObj.partnerType}/${partnerLinkingObj.partnerRegion}?${graphQLError.message}`);
    return;
  }
};

export const useSetLoyaltyInfoWithPolling = () => {
  const { idToken, accessToken, user } = useOAuthContext();
  const { partnerLevelId, setLoyaltyInfo } = usePersistenceContext();
  const { linkLoyaltyInfo, pollLoyaltyInfo } = useAPIContext();
  const { currentLoyaltyType, currentPartner, currentPartnerRegion } = useApplicationContext();

  const callback = useCallback(
    async (loyaltyId: string): Promise<boolean> => {
      const userId: string = user?.userId ?? '';
      const res = await createLoyaltyWithPolling({
        idToken: idToken || '',
        loyaltyId,
        accessToken,
        userId,
        loyaltyType: currentLoyaltyType.toLowerCase(),
        partnerType: currentPartner.toLowerCase(),
        partnerLevelId,
        partnerRegion: currentPartnerRegion.toLowerCase(),
        linkLoyaltyInfo,
        pollLoyaltyInfo,
        fromRegister: true,
        firstName: user?.firstName ?? '',
        lastName: user?.lastName ?? '',
        email: user?.attributes?.email,
      });

      setLoyaltyInfo({
        loading: 'completed',
        loyaltyId: res?.loyaltyId || '',
        loyaltyBarcode: res?.loyaltyBarcode || '',
      });

      return !!res;
    },
    [
      user,
      currentLoyaltyType,
      currentPartner,
      currentPartnerRegion,
      idToken,
      accessToken,
      linkLoyaltyInfo,
      partnerLevelId,
      pollLoyaltyInfo,
      setLoyaltyInfo,
    ],
  );

  return [callback];
};
