import { Region } from '../../interfaces/Region';
import { IUser } from '../../user/IUser';
import { Persistence } from '../../persistence/Persistence';
import { FlowOptions, FlowStepRuleFunctionVariadic } from '../flow-engine';
import { APIContextActions } from '../../api/APIContext';
import { getMarvelLogger } from '@marvel-common/bp-digital-logging';
import { Consent } from '../../user/IUserPii';

export const getConsentPrefix = ({ multiRegion, appShortName }: Region): string =>
  multiRegion ? `${appShortName}-` : '';

export const getAcceptedTc = (user: IUser, partnerRegionConfig: Region): string | undefined => {
  if (user.consents?.length) {
    return user.consents.find((f: Consent) => f.consentType === 'Terms and Conditions' && f.accepted)?.version;
  }
  return user?.attributes?.[`${getConsentPrefix(partnerRegionConfig)}tc-accepted`];
};

export const getAcceptedPp = (user: IUser, partnerRegionConfig: Region): string | undefined => {
  if (user.consents?.length) {
    return user.consents.find((f: Consent) => f.consentType === 'Privacy Policy' && f.accepted)?.version;
  }
  return user?.attributes?.[`${getConsentPrefix(partnerRegionConfig)}pp-accepted`];
};

export const getLatestTc = (partnerRegionConfig: Region): string | undefined => {
  return partnerRegionConfig.consents.currentTandCVersion;
};

export const getLatestPp = (partnerRegionConfig: Region): string | undefined => {
  return partnerRegionConfig.consents.currentPrivacyPolicyVersion;
};

export const getIsConsentUpdate = (user: IUser, partnerRegionConfig: Region): boolean =>
  !!getAcceptedTc(user, partnerRegionConfig) || !!getAcceptedPp(user, partnerRegionConfig);

const needsToAcceptTAndC = (user: IUser, partnerRegionConfig: Region): boolean => {
  const accepted = getAcceptedTc(user, partnerRegionConfig);
  const latest = getLatestTc(partnerRegionConfig);
  return accepted !== latest;
};

const needsToAcceptPP = (user: IUser, partnerRegionConfig: Region): boolean => {
  const accepted = getAcceptedPp(user, partnerRegionConfig);
  const latest = getLatestPp(partnerRegionConfig);
  return accepted !== latest;
};

const needsToAcknowledgeGeneralMarketingPreference = (user: IUser, partnerRegionConfig: Region): boolean => {
  if (user.consents?.length) {
    return !user.consents.find((f: Consent) => f.consentType === 'General Marketing' && f.accepted);
  }

  return user?.attributes?.[`${getConsentPrefix(partnerRegionConfig)}general-marketing`] === undefined;
};

const needsToAccceptAdditionalPartnerRegionSpecificConsents = (user: IUser, partnerRegionConfig: Region): boolean => {
  const consentKeyToNameMap: Record<string, string> = {
    'uber-pro-ridehailing': 'Uber Pro Ridehailing',
  };

  if (!partnerRegionConfig.consents?.additionalConsentsRequired?.length) {
    return false;
  }

  if (user.consents?.length) {
    return partnerRegionConfig.consents?.additionalConsentsRequired.some((key: string) => {
      return !user.consents?.find((f: Consent) => f.consentType === consentKeyToNameMap[key] && f.accepted);
    });
  }

  const consentPrefix = getConsentPrefix(partnerRegionConfig);
  return partnerRegionConfig.consents?.additionalConsentsRequired.some((consentType) => {
    return user?.attributes?.[`${consentPrefix}${consentType}`] === undefined;
  });
};

const savePreExistingIdpRecordFlag = async (
  apiContext: APIContextActions,
  idToken: string,
  preExistingIdpRecord: boolean,
  loyaltyType: string,
  partnerType: string,
) => {
  const logger = getMarvelLogger('savePreExistingIdpRecordFlag');
  logger.info(`Saving preExistingIdpRecordFlag: ${preExistingIdpRecord}`);
  await apiContext.patchMappings(idToken, { loyaltyType, partnerType, preExistingIdpRecord });
};

export const rule: FlowStepRuleFunctionVariadic = async function rule(
  apiContext: APIContextActions,
  flowOptions: Partial<FlowOptions>,
) {
  const { user, region: partnerRegionConfig, idToken, currentPartner, currentLoyaltyType } = flowOptions;

  if (Persistence.getConsentCheckOverrideStatus()) {
    return false;
  }
  let hasToAcceptPartnerConsent = false;
  if (currentPartner === 'uber-pro') {
    // checking for uber specific consents
    hasToAcceptPartnerConsent = needsToAccceptAdditionalPartnerRegionSpecificConsents(user!, partnerRegionConfig!);
  }

  const hasToAcceptTAndC = needsToAcceptTAndC(user!, partnerRegionConfig!);
  const hasToAcceptPP = needsToAcceptPP(user!, partnerRegionConfig!);

  // accept partner consents
  if (hasToAcceptPartnerConsent) {
    let preExistingIdpRecord = false;
    if (!hasToAcceptPP && !hasToAcceptTAndC) {
      preExistingIdpRecord = true;
    }
    await savePreExistingIdpRecordFlag(
      apiContext,
      idToken as string,
      preExistingIdpRecord,
      currentLoyaltyType as string,
      currentPartner as string,
    );
  }

  const result =
    hasToAcceptTAndC ||
    hasToAcceptPP ||
    hasToAcceptPartnerConsent ||
    needsToAcknowledgeGeneralMarketingPreference(user!, partnerRegionConfig!);

  return result;
};
