import { Tokens } from '@forgerock/javascript-sdk';

async function get(clientId: string): Promise<Tokens> {
  const v = window.sessionStorage.getItem(clientId);
  return v ? JSON.parse(v) : undefined;
}

async function set(clientId: string, token: Tokens): Promise<void> {
  return window.sessionStorage.setItem(
    clientId,
    JSON.stringify({
      accessToken: token.accessToken,
      idToken: token.idToken,
      tokenExpiry: token.tokenExpiry,
    }),
  );
}

async function remove(clientId: string): Promise<void> {
  return window.sessionStorage.removeItem(clientId);
}

export const cipTokenStore = {
  get,
  set,
  remove,
};
