import saveUserV2CIPMutation from 'graphql-tag';

export default saveUserV2CIPMutation`
  mutation saveUserCIP($tokenInput: TokenInput) {
    saveUserV2(tokenInput: $tokenInput)
  }
`;
export type TokenInput = {
  refreshToken: string;
  accessToken: string;
  uberRefreshToken?: string;
};
