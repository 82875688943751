import { getMarvelLogger } from '@marvel-common/bp-digital-logging';
import { Component, ReactNode } from 'react';

/**
 * This component captures any errors that were not caught properly by
 * any other component in the app.
 * It then logs such errors, which triggers sending the errors to AppSync,
 * and thus leading to a new Slack alert.
 *
 */

interface ErrorBoundaryProps {
  children?: ReactNode;
}

class ErrorBoundary extends Component<ErrorBoundaryProps> {
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  componentDidCatch(error: Error, _errorInfo: any): void {
    const logger = getMarvelLogger('ErrorBoundary:componentDidCatch');
    logger.alert({ message: (error as Error).message, stack: error });
    logger.error('Error on frontend:', error, _errorInfo);
  }

  render(): ReactNode {
    return this.props.children;
  }
}

export default ErrorBoundary;
