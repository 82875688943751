import { ReactElement, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import AnalyticsContainer from '../../analytics/AnalyticsContainer';
import LanguageSelector from '../../components/LanguageSelector/LanguageSelector';

import history from '../../routing/BrowserHistory';
import { useTranslation } from 'react-i18next';

import { useAppState } from '../../components/app/useAppState';
import { IAppCallBackProps } from '../../routing/RouterComponentSwitcher';
import { useApplicationContext } from '../../contexts/ApplicationContext';
import { getDefaultLanguage, getSupportedLanguages } from '../../helpers/PartnerRegionPropertySelector';
import PartnerRegionsSelector, {
  SupportedRegionListMode,
} from '../../components/PartnerRegionsSelector/PartnerRegionsSelector';

interface ITermsAndConditionsSelectorProps extends IAppCallBackProps {
  partner: string;
}

const TermsAndConditionsSelector = (props: ITermsAndConditionsSelectorProps): ReactElement => {
  const { currentLanguage } = useApplicationContext();
  const { t } = useTranslation('termsRegionSelector');
  const { setShowFooter, changeLanguage } = useAppState();

  const handleLanguageChange = (language: string) => {
    changeLanguage(language);
  };

  const handleViewTermsAndConditions = (path: string, region: string) => {
    const supportedLanguagesForRegion = getSupportedLanguages(props.partner, region);
    if (supportedLanguagesForRegion.indexOf(currentLanguage) < 0) {
      const regionDefaultLanguage = getDefaultLanguage(props.partner, region);
      changeLanguage(regionDefaultLanguage);
    }
    history.push(path);
  };

  useEffect(() => {
    setShowFooter(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <AnalyticsContainer
      pageName="Terms and Conditions - Region Selector" // donot translate
    >
      <Container data-testid="terms-and-conditions-region-selector-container">
        <Row>
          <Col className="text-right">
            <LanguageSelector partner={props.partner} onLanguageChange={handleLanguageChange} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h1 className="remove-top-padding">{t('title')}</h1>
            <p>{t('instructions')}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <PartnerRegionsSelector
              mode={SupportedRegionListMode.TermsAndConditions}
              partner={props.partner}
              onViewLinkForRegion={handleViewTermsAndConditions}
            />
          </Col>
        </Row>
      </Container>
    </AnalyticsContainer>
  );
};

export default TermsAndConditionsSelector;
