import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useApplicationContext } from '../../contexts/ApplicationContext';

export function useShowBackButton() {
  const [visible, setVisible] = useState(false);

  const { currentPartnerRegion } = useApplicationContext();
  const pageName = useLocation();

  useEffect(() => {
    const phoneVerificationRegions = ['uk', 'us', 'au'];

    if (pageName?.pathname?.includes('passcode-verify')) {
      setVisible(phoneVerificationRegions.some((x) => x === currentPartnerRegion));
      return;
    }
    setVisible(false);
  }, [pageName?.pathname, currentPartnerRegion]);

  return visible;
}
