import { staticConfig } from '../config';

/**
 * Development = DEV environment
 * Testing = For unit test/UI test
 * Staging = TEST environment
 * Production = PROD environment
 */

/* istanbul ignore next: No need to codecov this helper */
export const isDev = staticConfig.system.environment === 'development' || staticConfig.system.environment === 'local';

/* istanbul ignore next: No need to codecov this helper */
export const isTesting = staticConfig.system.environment === 'testing';

/* istanbul ignore next: No need to codecov this helper */
export const isStaging = staticConfig.system.environment === 'staging';

/* istanbul ignore next: No need to codecov this helper */
export const isProd = staticConfig.system.environment === 'production';

/* istanbul ignore next: No need to codecov this helper */
export const getLogLevel = isProd ? 'warn' : 'debug';
