import { Guid } from 'guid-typescript';
import { encryptValue, decryptValue } from '../helpers/CryptoHelper';
import { RegionType } from '../helpers/RegionType';

export interface ISettingsProps {
  anonymousId: string;
}

export class Persistence {
  static readonly initialState: ISettingsProps = {
    anonymousId: Guid.create().toString(),
  };

  static readonly MARVEL_SETTINGS_ANONYMOUS_IDENTITY_KEY = 'MARVEL_SETTINGS_ANONYMOUS_IDENTITY_KEY';
  static readonly MARVEL_SETTINGS_PARTNER_KEY = 'MARVEL_SETTINGS_PARTNER_KEY';
  static readonly MARVEL_SETTINGS_PROGRAM_KEY = 'MARVEL_SETTINGS_PROGRAM_KEY';
  static readonly MARVEL_SETTINGS_PARTNER_LEVEL_KEY = 'MARVEL_SETTINGS_PARTNER_LEVEL_KEY';
  static readonly MARVEL_SETTINGS_PARTNER_LEVEL_ID_KEY = 'MARVEL_SETTINGS_PARTNER_LEVEL_ID_KEY';
  static readonly MARVEL_SETTINGS_LANGUAGE_KEY = 'MARVEL_SETTINGS_LANGUAGE_KEY';
  static readonly MARVEL_SETTINGS_PARTNER_REGION_KEY = 'MARVEL_SETTINGS_PARTNER_REGION_KEY';
  static readonly MARVEL_SETTINGS_LOCALE_KEY = 'MARVEL_SETTINGS_LOCALE_KEY';
  static readonly MARVEL_SETTINGS_STATE_ENC = 'MARVEL_SETTINGS_STATE_ENC';
  static readonly MARVEL_SETTINGS_LAST_LOCATION = 'MARVEL_SETTINGS_LAST_LOCATION';
  static readonly MARVEL_SETTINGS_REDIRECT_AFTER_LOGIN = 'MARVEL_SETTINGS_REDIRECT_AFTER_LOGIN';
  static readonly MARVEL_SETTINGS_LOYALTY_TYPE = 'MARVEL_SETTINGS_LOYALTY_TYPE';
  static readonly MARVEL_SETTINGS_LOYALTY_ID = 'MARVEL_SETTINGS_LOYALTY_CARD';
  static readonly MARVEL_SETTINGS_LOYALTY_BARCODE = 'MARVEL_SETTINGS_LOYALTY_BARCODE';
  static readonly MARVEL_SETTINGS_HASHED_OBJECT = 'MARVEL_SETTINGS_HASHED_OBJECT';
  static readonly MARVEL_SETTINGS_PHONE_NUMBER_KEY = 'MARVEL_SETTINGS_PHONE_NUMBER_KEY';
  static readonly MARVEL_SETTINGS_PHONE_VERIFIED_KEY = 'MARVEL_SETTINGS_PHONE_VERIFIED_KEY';
  static readonly MARVEL_SETTINGS_FIRST_NAME = 'MARVEL_SETTINGS_FIRST_NAME';
  static readonly AUTOMATION_TESTING_ID_TOKEN = 'AUTOMATION_TESTING_ID_TOKEN';
  static readonly AUTOMATION_TESTING_ACCESS_TOKEN = 'AUTOMATION_TESTING_ACCESS_TOKEN';
  static readonly MARVEL_SETTINGS_PARTNER_TIER_OFFER_VALUE = 'MARVEL_SETTINGS_PARTNER_TIER_OFFER_VALUE';
  static readonly MARVEL_SETTINGS_PARTNER_PRICE_MATCH_OFFER = 'MARVEL_SETTINGS_PARTNER_PRICE_MATCH_OFFER';
  static readonly MARVEL_SETTINGS_CODE_VERIFIER = 'MARVEL_SETTINGS_CODE_VERIFIER';
  static readonly MARVEL_SETTINGS_CODE_CHALLENGE = 'MARVEL_SETTINGS_CODE_CHALLENGE';
  static readonly MARVEL_SETTINGS_CONSENT_STATUS = 'MARVEL_SETTINGS_CONSENT_STATUS';
  static readonly MARVEL_SETTINGS_PARTNER_STATUS = 'MARVEL_SETTINGS_PARTNER_STATUS';
  static readonly MARVEL_SETTINGS_ANALYTICS_SLUG_KEY = 'MARVEL_SETTINGS_ANALYTICS_SLUG_KEY';
  static readonly MARVEL_SETTINGS_PASSWORDLESS_ENABLED = 'MARVEL_SETTINGS_PASSWORDLESS_ENABLED';

  static encryptionKey = '';

  static fetchSettings = (): ISettingsProps => {
    const settings: ISettingsProps = {
      anonymousId: Persistence.initialState.anonymousId,
    };

    const id = localStorage.getItem(Persistence.MARVEL_SETTINGS_ANONYMOUS_IDENTITY_KEY);

    if (id) settings.anonymousId = id;

    return settings;
  };

  static setStorageEncryptionKey = (encKey: string): void => {
    Persistence.encryptionKey = encKey;
  };

  static storeSettings = (settings: ISettingsProps): void => {
    Persistence.setValueForKey(settings.anonymousId!, Persistence.MARVEL_SETTINGS_ANONYMOUS_IDENTITY_KEY);
  };

  private static setValueForKey = (value: string, key: string): void => {
    if (value && value.length > 0) {
      localStorage.setItem(key, value);
    }
  };

  static clear = (): void => {
    localStorage.clear();
  };

  static setLastLocation = (value: string): void => {
    Persistence.setValueForKey(value, Persistence.MARVEL_SETTINGS_LAST_LOCATION);
  };

  static getLastLocation = (): string | null => {
    return localStorage.getItem(Persistence.MARVEL_SETTINGS_LAST_LOCATION);
  };

  static setShouldAuthenticateAndRedirectToLastLocation = (value: boolean): void => {
    Persistence.setValueForKey(value ? '1' : '0', Persistence.MARVEL_SETTINGS_REDIRECT_AFTER_LOGIN);
  };

  static getShouldAuthenticateAndRedirectToLastLocation = (): boolean => {
    return Boolean(JSON.parse(localStorage.getItem(Persistence.MARVEL_SETTINGS_REDIRECT_AFTER_LOGIN) || 'false'));
  };

  static setAnalyticsSlug = (value: string): void => {
    Persistence.setValueForKey(value, Persistence.MARVEL_SETTINGS_ANALYTICS_SLUG_KEY);
  };

  static getAnalyticsSlug = (): string | null => {
    return localStorage.getItem(Persistence.MARVEL_SETTINGS_ANALYTICS_SLUG_KEY);
  };

  static setPartner = (value: string): void => {
    Persistence.setValueForKey(value, Persistence.MARVEL_SETTINGS_PARTNER_KEY);
  };

  static getPartner = (): string | null => {
    return localStorage.getItem(Persistence.MARVEL_SETTINGS_PARTNER_KEY);
  };

  static getAuthRoute = (): string | null => {
    const partner = Persistence.getPartner();
    const partnerRegion = Persistence.getPartnerRegion();
    if (partner === null || partnerRegion === null) return null;
    return `/${partner}/${partnerRegion}/`;
  };

  static setPhoneNumber = (phoneNumber: string): void => {
    const encryptedPhoneNumber = Persistence.encryptStorageValue(phoneNumber) as string;
    Persistence.setValueForKey(encryptedPhoneNumber, Persistence.MARVEL_SETTINGS_PHONE_NUMBER_KEY);
  };

  static getPhoneNumber = (): string | null => {
    const encryptedPhoneNumber = localStorage.getItem(Persistence.MARVEL_SETTINGS_PHONE_NUMBER_KEY);
    if (encryptedPhoneNumber) {
      return Persistence.decryptStorageValue(encryptedPhoneNumber);
    }
    return encryptedPhoneNumber;
  };

  static setFirstName = (firstName: string): void => {
    const encryptedFirstName = Persistence.encryptStorageValue(firstName) as string;
    Persistence.setValueForKey(encryptedFirstName, Persistence.MARVEL_SETTINGS_FIRST_NAME);
  };

  static getFirstName = (): string | null => {
    const encryptedFirstName = localStorage.getItem(Persistence.MARVEL_SETTINGS_FIRST_NAME);
    if (encryptedFirstName) {
      return Persistence.decryptStorageValue(encryptedFirstName);
    }
    return encryptedFirstName;
  };

  static setPartnerTierOfferValue = (value: number | null): void => {
    if (value !== null) {
      Persistence.setValueForKey(value.toString(), Persistence.MARVEL_SETTINGS_PARTNER_TIER_OFFER_VALUE);
    }
  };

  static getPartnerTierOfferValue = (): number | null => {
    const value = localStorage.getItem(Persistence.MARVEL_SETTINGS_PARTNER_TIER_OFFER_VALUE);
    return value ? parseInt(value) : null;
  };

  static setPartnerPriceMatchOffer = (value: boolean | null): void => {
    if (value !== null) {
      Persistence.setValueForKey(value.toString(), Persistence.MARVEL_SETTINGS_PARTNER_PRICE_MATCH_OFFER);
    }
  };

  static getPartnerPriceMatchOfferActiveStatus = (): boolean => {
    const value = localStorage.getItem(Persistence.MARVEL_SETTINGS_PARTNER_PRICE_MATCH_OFFER);
    return value ? JSON.parse(value) : false;
  };

  static setPartnerLevel = (value: string): void => {
    const encryptedPartnerLevel = Persistence.encryptStorageValue(value) as string;
    Persistence.setValueForKey(encryptedPartnerLevel, Persistence.MARVEL_SETTINGS_PARTNER_LEVEL_KEY);
  };

  static getPartnerLevel = (): string | null => {
    const partnerLevel = localStorage.getItem(Persistence.MARVEL_SETTINGS_PARTNER_LEVEL_KEY);
    if (partnerLevel) {
      return Persistence.decryptStorageValue(partnerLevel);
    }

    return partnerLevel;
  };

  static setPartnerLevelId = (value: string): void => {
    const encryptedPartnerLevelId = Persistence.encryptStorageValue(value) as string;
    Persistence.setValueForKey(encryptedPartnerLevelId, Persistence.MARVEL_SETTINGS_PARTNER_LEVEL_ID_KEY);
  };

  static getPartnerLevelId = (): string | null => {
    const partnerLevelId = localStorage.getItem(Persistence.MARVEL_SETTINGS_PARTNER_LEVEL_ID_KEY);
    if (partnerLevelId) {
      return Persistence.decryptStorageValue(partnerLevelId);
    }

    return partnerLevelId;
  };

  static setLoyaltyId = (value: string): void => {
    const encryptedLoyaltyId = Persistence.encryptStorageValue(value) as string;
    Persistence.setValueForKey(encryptedLoyaltyId, Persistence.MARVEL_SETTINGS_LOYALTY_ID);
  };

  static getLoyaltyId = (): string | null => {
    const loyaltyId = localStorage.getItem(Persistence.MARVEL_SETTINGS_LOYALTY_ID);
    if (loyaltyId) {
      return Persistence.decryptStorageValue(loyaltyId);
    }

    return loyaltyId;
  };

  static removeLoyaltyId = (): void => {
    localStorage.removeItem(Persistence.MARVEL_SETTINGS_LOYALTY_ID);
  };

  static setLoyaltyBarcode = (value: string): void => {
    const encryptedLoyaltyBarcode = Persistence.encryptStorageValue(value) as string;
    Persistence.setValueForKey(encryptedLoyaltyBarcode, Persistence.MARVEL_SETTINGS_LOYALTY_BARCODE);
  };

  static getLoyaltyBarcode = (): string | null => {
    const loyaltyBarcode = localStorage.getItem(Persistence.MARVEL_SETTINGS_LOYALTY_BARCODE);
    if (loyaltyBarcode) {
      return Persistence.decryptStorageValue(loyaltyBarcode);
    }

    return loyaltyBarcode;
  };

  static removeLoyaltyBarcode = (): void => {
    localStorage.removeItem(Persistence.MARVEL_SETTINGS_LOYALTY_BARCODE);
  };

  static setLoyaltyType = (value: string): void => {
    Persistence.setValueForKey(value, Persistence.MARVEL_SETTINGS_LOYALTY_TYPE);
  };

  static getLoyaltyType = (): string | null => {
    return localStorage.getItem(Persistence.MARVEL_SETTINGS_LOYALTY_TYPE);
  };

  static setStateEnc = (location: string): void => {
    Persistence.setValueForKey(location, Persistence.MARVEL_SETTINGS_STATE_ENC);
  };

  static getStateEnc = (): string | null => {
    return localStorage.getItem(Persistence.MARVEL_SETTINGS_STATE_ENC);
  };

  static removeStateEnc = (): void => {
    localStorage.removeItem(Persistence.MARVEL_SETTINGS_STATE_ENC);
  };

  static setPartnerRegion = (value: RegionType): void => {
    Persistence.setValueForKey(value, Persistence.MARVEL_SETTINGS_PARTNER_REGION_KEY);
  };

  static getPartnerRegion = (): RegionType | null => {
    return localStorage.getItem(Persistence.MARVEL_SETTINGS_PARTNER_REGION_KEY) as RegionType;
  };

  static setLanguage = (value: string): void => {
    Persistence.setValueForKey(value, Persistence.MARVEL_SETTINGS_LANGUAGE_KEY);
  };

  static getLanguage = (): string | null => {
    return localStorage.getItem(Persistence.MARVEL_SETTINGS_LANGUAGE_KEY);
  };

  static setLocale = (value: string): void => {
    Persistence.setValueForKey(value, Persistence.MARVEL_SETTINGS_LOCALE_KEY);
  };

  static getLocale = (): string | null => {
    return localStorage.getItem(Persistence.MARVEL_SETTINGS_LOCALE_KEY);
  };

  static setAnonymousId = (value: string): void => {
    Persistence.setValueForKey(value, Persistence.MARVEL_SETTINGS_ANONYMOUS_IDENTITY_KEY);
  };

  static getAnonymousId = (): string | null => {
    return localStorage.getItem(Persistence.MARVEL_SETTINGS_ANONYMOUS_IDENTITY_KEY);
  };

  static setHashedObject = (value: string): void => {
    Persistence.setValueForKey(value, Persistence.MARVEL_SETTINGS_HASHED_OBJECT);
  };

  static getHashedObject = (): string | null => {
    return localStorage.getItem(Persistence.MARVEL_SETTINGS_HASHED_OBJECT);
  };

  static setCodeVerifier = (value: string, keySuffix: string): void => {
    Persistence.setValueForKey(value, `${Persistence.MARVEL_SETTINGS_CODE_VERIFIER}-${keySuffix}`);
  };

  static getCodeVerifier = (keySuffix: string): string | null => {
    return localStorage.getItem(`${Persistence.MARVEL_SETTINGS_CODE_VERIFIER}-${keySuffix}`);
  };

  static removeCodeVerifier = (keySuffix: string): void => {
    localStorage.removeItem(`${Persistence.MARVEL_SETTINGS_CODE_VERIFIER}-${keySuffix}`);
  };

  static setCodeChallenge = (value: string, keySuffix: string): void => {
    Persistence.setValueForKey(value, `${Persistence.MARVEL_SETTINGS_CODE_CHALLENGE}-${keySuffix}`);
  };

  static getCodeChallenge = (keySuffix: string): string | null => {
    return localStorage.getItem(`${Persistence.MARVEL_SETTINGS_CODE_CHALLENGE}-${keySuffix}`);
  };

  static removeCodeChallenge = (keySuffix: string): void => {
    localStorage.removeItem(`${Persistence.MARVEL_SETTINGS_CODE_CHALLENGE}-${keySuffix}`);
  };

  static setPasswordlessStatus = (value: boolean): void => {
    Persistence.setValueForKey(value ? '1' : '0', Persistence.MARVEL_SETTINGS_PASSWORDLESS_ENABLED);
  };

  static getPasswordlessStatus = (): boolean => {
    return Boolean(JSON.parse(localStorage.getItem(Persistence.MARVEL_SETTINGS_PASSWORDLESS_ENABLED) || 'false'));
  };

  static removePasswordlessStatus = (): void => {
    localStorage.removeItem(Persistence.MARVEL_SETTINGS_PASSWORDLESS_ENABLED);
  };

  static resetUserData = (): void => {
    localStorage.removeItem(Persistence.MARVEL_SETTINGS_LOYALTY_ID);
    localStorage.removeItem(Persistence.MARVEL_SETTINGS_PARTNER_LEVEL_ID_KEY);
    localStorage.removeItem(Persistence.MARVEL_SETTINGS_PARTNER_LEVEL_KEY);
    localStorage.removeItem(Persistence.MARVEL_SETTINGS_ANALYTICS_SLUG_KEY);
    localStorage.removeItem(Persistence.MARVEL_SETTINGS_ANONYMOUS_IDENTITY_KEY);
    localStorage.removeItem(Persistence.MARVEL_SETTINGS_HASHED_OBJECT);
  };

  static setConsentCheckOverrideStatus = (value: boolean | null): void => {
    if (value !== null) {
      Persistence.setValueForKey(value.toString(), Persistence.MARVEL_SETTINGS_CONSENT_STATUS);
    }
  };

  static getConsentCheckOverrideStatus = (): boolean => {
    const value = localStorage.getItem(Persistence.MARVEL_SETTINGS_CONSENT_STATUS);
    return value ? JSON.parse(value) : false;
  };

  static setPartnerStatus = (value: boolean | null): void => {
    if (value !== null) {
      Persistence.setValueForKey(value.toString(), Persistence.MARVEL_SETTINGS_PARTNER_STATUS);
    }
  };

  static removePartnerStatus = (): void => {
    localStorage.removeItem(Persistence.MARVEL_SETTINGS_PARTNER_STATUS);
  };

  static getPartnerStatus = (): boolean => {
    const value = localStorage.getItem(Persistence.MARVEL_SETTINGS_PARTNER_STATUS);
    return value ? JSON.parse(value) : false;
  };

  private static encryptStorageValue(value: string): string | null {
    try {
      return encryptValue(value, Persistence.encryptionKey);
    } catch (err) {
      return null;
    }
  }

  private static decryptStorageValue(value: string): string | null {
    try {
      return decryptValue(value, Persistence.encryptionKey);
    } catch (err) {
      return null;
    }
  }

  // WARNING: ONLY USE THIS METHOD FOR AUTOMATION TESTING PURPOSES
  /* istanbul ignore next: No need to codecov, only used in automation testing */
  static __storeTokensForAutomationTestingOnly = (accessToken: string, idToken: string): void => {
    Persistence.setValueForKey(accessToken, Persistence.AUTOMATION_TESTING_ACCESS_TOKEN);
    Persistence.setValueForKey(idToken, Persistence.AUTOMATION_TESTING_ID_TOKEN);
  };
}
