import CryptoJs from 'crypto-js';

export function encryptValue(valueToEncrypt: string, encryptionKey: string): string {
  if (!encryptionKey || !valueToEncrypt) {
    throw new Error('invalid params for encryption');
  }

  const encryptedValue = CryptoJs.AES.encrypt(valueToEncrypt, encryptionKey).toString();
  return encryptedValue;
}

export function decryptValue(valueToDecrypt: string, encryptionKey: string): string {
  if (!encryptionKey || !valueToDecrypt) {
    throw new Error('invalid params for decryption');
  }

  const decryptedValue = CryptoJs.AES.decrypt(valueToDecrypt, encryptionKey).toString(CryptoJs.enc.Utf8);
  return decryptedValue;
}

export function getHash(value: string): string {
  return CryptoJs.SHA256(value).toString();
}
