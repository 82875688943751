import { AuthState } from './OAuthContext';

export enum UserMutations {
  PERSONALISATION = 'PERSONALISATION',
}

export interface UserMutationAction extends AuthState {
  type: UserMutations | undefined;
}

export default (state: AuthState, action: UserMutationAction): AuthState => {
  switch (action.type) {
    case UserMutations.PERSONALISATION: {
      const newUser = Object.assign({}, state.user, {
        firstName: action.user?.firstName,
        lastName: action.user?.lastName,
      });
      const newState = Object.assign({}, state, { user: newUser });
      return newState;
    }
    default:
      return state;
  }
};
