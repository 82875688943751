export default {
  default: {
    loading: 'Cargando',
    redirecting: 'Redirigiendo',
    processing: 'Procesando',
  },
  title: {
    uberproMX: 'bp Reward | Earn more with Uber Pro in Mexico',
  },
  termsRegionSelector: {
    instructions: 'Escoge tu ubicación:',
    title: 'Términos y condiciones',
  },
  termsMXv1: {
    back: 'Atrás',
    description: 'Términos y Condiciones ',
    title: 'termsMXv1 title',
  },
  privacyRegionSelector: {
    instructions: 'Escoge tu ubicación:',
    title: 'Politicas de Privacidad ',
  },
  privacyMXv1: {
    back: 'Atrás',
    description: 'privacyMXv1 description',
    title: 'Politicas de Privacidad',
  },
  faqMX: {
    back: 'Atrás',
    description: 'FAQ for the Netherlands',
    title: 'Veelgestelde vragen',
  },
  home: {
    points: 'Puntos',
    point: 'Punto',
    youHave: 'Tienes',
    barcodeInstructions:
      // === available params ===
      // {{loyaltyType}}
      'no barcode in MX',
    linkExplanation:
      // === available params ===
      // {{loyaltyType}}
      // {{partnerType}}
      // {{partnerLevel}}
      // {{loyaltyId}}
      'Tu Monedero {{loyaltyType}} <1>{{loyaltyId}}</1> está registrado con tu usuario {{partnerType}}. Como conductor {{partnerLevel}} ahora podrás acumular Puntos {{loyaltyType}}. Recuerda que en tu primera carga recibirás 150 Puntos {{loyaltyType}} de bienvenida.',
  },
  paybackModal: {
    title: '¡Ven a pb, carga y paga con Puntos PAYBACK!',
    main1: 'Aún estás a tiempo, ven a bp y utiliza tus Puntos PAYBACK.',
    main2: 'Recuerda que puedes utilizar tu Tarjeta PAYBACK para',
    listItem1: 'acumule puntos PAYBACK',
    listItem1Bold: 'hasta el 31 de julio',
    listItem2: 'y canjear puntos PAYBACK',
    listItem2Bold: 'hasta el 31 de octubre.',
  },
  bpMeRewardsWelcome: {
    greeting:
      // === available params ===
      'Hola',
  },
  paybackRewardsHome: {
    fixedForPartnerLevel:
      // === available params ===
      // {{partnerLevel}}
      'fixed for {{partnerLevel}} tier drivers',
    centsPerGallon: '¢ / gallon',
    priceMatchTitle: 'Price Match',
    priceMatchDescription: 'Price assured on your next purchase',
    priceMatchSubscription:
      // === available params ===
      // {{partnerLevel}}
      'free subscription for {{partnerLevel}} tier drivers',
    promoHeading: 'Con BPme factura fácil.  ',
    promoContentPart1:
      'BPme es nuestra aplicación para dispositivos móviles con sistemas operativos Android y iOS desde la cual se puede facturar y ubicar estaciones.',
    promoContentPart2:
      'Gracias a la tecnología cognitiva con la que ha sido desarrollada, BPme resulta amigable y fácil de usar.',
    promoDownloadBpMeApp: 'Obtener BPme',
    promoHeading2: 'Regístrate y gana todos los días',
    promoDownloadPaybackApp: 'Descarda la PAYBACK app',

    gettingStarted: 'Getting started',
    aboutTheOffer: 'About the offer',
    accessingYourBpMe: 'Accessing your BPme Rewards account',
    maximizeYourSavings: 'Maximize your savings',
  },
  bpMeRewardsHome: {
    fixedForPartnerLevel:
      // === available params ===
      // {{partnerLevel}}
      'fixed for {{partnerLevel}} tier drivers',
    centsPerGallon: '¢ / gallon',
    priceMatchTitle: 'Price Match',
    priceMatchDescription: 'Price assured on your next purchase',
    priceMatchSubscription:
      // === available params ===
      // {{partnerLevel}}
      'free subscription for {{partnerLevel}} tier drivers',
    promoHeading: 'Con BPme factura fácil.  ',
    promoContentPart1:
      'BPme es nuestra aplicación para dispositivos móviles con sistemas operativos Android y iOS desde la cual se puede facturar y ubicar estaciones.',
    promoContentPart2:
      'Gracias a la tecnología cognitiva con la que ha sido desarrollada, BPme resulta amigable y fácil de usar.',
    promoDownloadBpMeApp: 'Obtener BPme',
    promoHeading2: 'Regístrate y gana todos los días',
    promoDownloadPaybackApp: 'Descarda la PAYBACK app',

    gettingStarted: 'Getting started',
    aboutTheOffer: 'About the offer',
    accessingYourBpMe: 'Accessing your BPme Rewards account',
    maximizeYourSavings: 'Maximize your savings',
  },
  footer: {
    // === available params ===
    // {{startYear}}
    // {{endYear}}
    copyright: 'Copyright © {{startYear}}-{{endYear}}',
    linkFAQ:
      // === indexes ===
      // 0 = Link to FAQ
      '<0>Preguntas Frecuentes</0>',
    linkTermsAndConditions:
      // === indexes ===
      // 0 = Link to privacy statement
      '<0>Términos y condiciones</0>',
    linkPrivacyStatement:
      // === indexes ===
      // 0 = Link to privacy statement
      '<0>Aviso de privacidad</0>',
    linkContactUs:
      // === indexes ===
      // 0 = Link to contact us
      '<0>Contáctanos</0>',
  },
  welcome: {
    heading: 'Bienvenido',
    intro:
      // === available params ===
      // {{partnerType}}
      // {{loyaltyType}}
      // {{partnerLevel]]}
      'Hemos revisado tu perfil de {{partnerType}}. Como conductor {{partnerLevel}}, eres elegible para ganar Puntos {{loyaltyCardType}}.<br /> <br />' +
      '¡Ahora sólo registra tu Monedero {{loyaltyCardType}} y listo! ¡Fácil y rápido! Además, en tu primera carga recibe 150 Puntos PAYBACK de bienvenida!',
    partnerCta:
      // === available params ===
      // {{loyaltyCardType}}
      'Registra tu Monedero {{loyaltyCardType}}',
    partnerHeading:
      // === available params ===
      // {{loyaltyCardType}}
      '¿No tienes un Monedero {{loyaltyCardType}}?',
    partnerPromo:
      // === available params ===
      // {{loyaltyCardType}}
      // {{loyaltyCardLinkDomain}}
      // === indexes ===
      // 2 = Link to sign up
      'No te preocupes, puedes solicitar un Monedero en línea, o solicitar uno en tu estación bp más cercana.<br /><br />' +
      '<2>Solicita un Monedero {{loyaltyCardType}}</2>',
  },
  linkPaybackCard: {
    title:
      // === available params ===
      // {{loyaltyCardType}}
      'Registra tu Monedero {{loyaltyCardType}}',
    requirements: 'Sólo necesitamos tu número de Monedero.',
    labelCardNumber:
      // === available params ===
      // {{loyaltyCardType}}
      'Número de Monedero {{loyaltyCardType}}',
    labelCardNumberLocation:
      // === available params ===
      // {{loyaltyCardType}}
      'Este es el número de 10 dígitos, localizado al reverso de tu Monedero.',
    labelCardNumberError:
      // === available params ===
      // {{loyaltyCardType}}
      'Por favor ingresa los 10 dígitos de tu Monedero {{loyaltyCardType}}.',
    labelPin:
      // === available params ===
      // {{loyaltyCardType}}
      'PIN (4 digits)',
    labelPinForgot:
      // === available params ===
      // {{forgottenPasswordLinkDomain}}
      // === indexes ===
      // 2 = Link to forgotten password
      'Forgotten your PIN <2>Reset it now.</2>',
    physicalCardNoPin:
      // === available params ===
      // {{loyaltyCardType}}
      // {{registrationLinkDomain}}
      // === indexes ===
      // 1 = Link to register account
      'Remember you need to register your Wallet to get a PIN. <1>Register {{loyaltyCardType}} Wallet.</1>.',
    labelPinError: 'PIN ERROR',
    buttonConfirm: 'Confirmar',
    buttonCancel: 'Cancelar',
    submissionErrorAlreadyLinkedTitle: 'Este número de {{loyaltyCardType}} ya está registrado con otra cuenta',
    submissionErrorAlreadyLinkedDetails:
      'Si tus datos han cambiado y necesitas actualizarlos, por favor contacta a nuestro <1>servicio al cliente</1>.',
    submissionErrorTitle: 'No pudimos registrar tu Monedero {{loyaltyCardType}}',
    submissionErrorDetails: 'Por favor valida tu numero de Monedero {{loyaltyCardType}} e inténtalo nuevamente.',
  },
  tandcMX: {
    reviewPolicies:
      // === indexes ===
      // 1 = Link to terms and conditions
      // 3 = Link to privacy policy
      'Para terminar tu registro al programa de bp, por favor revisa y acepta los <1>términos y condiciones</1>, y revisa nuestro <3>Aviso de privacidad.</3>',
    labelTandC: 'Acepto los términos y condiciones',
    marketingSectionHeader:
      'Mantente actualizado con nuestra oferta y las últimas noticias para que puedas aprovechar el programa de lealtad {{loyaltyCardType}} ',
    emailMarketingTitle: 'Quiero recibir vía correo electrónico actualizaciones sobre el programa {{loyaltyCardType}}',
    acceptError: 'Por favor acepta los términos y condiciones.',
    nameLabel: 'Nombre',
    title: 'Términos y condiciones',
    lastNameLabel: 'Apellido',
    emailLabel: 'Correo electrónico',
    smsMarketingTitle: 'Contáctame vía mensaje de texto con actualizaciones sobre el programa de lealtad',
    registrationTitle: 'Empecemos con tu registro',
    registrationHelperText: 'Configuraremos tu cuenta BPMe usando la información que tenemos de xxx.',
    smsMarketingSubTitle:
      'Promociones y mensajes sobre el programa de lealtad estarán llegando de manera automática al numero de celular que nos compartiste. Manda el mensase “PARA” para detener estos envíos, o “AYUDA” en caso de necesitarla.',
    buttonSubmit: 'Confirmar',
  },
  tandcGlobal: {
    title: 'Casi terminamos',
    reviewPolicies:
      // === indexes ===3
      // 1 = Link to terms and conditions
      // 3 = Link to privacy policy
      'Para terminar tu registro al programa de bp, por favor revisa y acepta los <1>términos y condiciones</1>, y revisa nuestro <3>Aviso de privacidad.</3>',
    labelTandC: 'Acepto los términos y condiciones',
    linkTandC: 'Términos y condiciones',
    acceptAgreement: 'By creating an account, you are agreeing to bp’s Terms and Conditions.',
    labelMarketing: 'I would like to receive bp marketing emails ',
    agreeMarketing: 'If you wish to receive marketing communications, please tick this box.',
  },
  languageSelector: {
    en: 'Inglés (English)',
    nl: 'Holandés (Dutch)',
    es: 'Español',
  },
  error: {
    title401: 'Error 401 ',
    heading401: 'No tienes los permisos para ingresar a esta página',
    message401:
      // === available params ===
      // {{partnerType}}
      'Necesitas ingresar con tus credenciales para poder acceder a este sitio. Por favor ingresa usando tu emailcorreo y contraseña registrados en {{partnerType}}.',
    cta401: 'Ingresar',
    title404: 'Error 404 ',
    heading404: 'Lo sentimos, no podemos encontrar esta página.',
    message404: 'Esta página no existe, por favor revísala e intenta de nuevo.',
    cta404: 'Atrás',
    title500: 'Error 500',
    heading500: '¡Algo salió mal!',
    message500:
      'Nuestros servicios no están funcionando por el momento, estamos trabajando para solucionarlo. Por favor inténtalo más tarde.',
    prime500:
      'We’re thrilled that so many Prime members are interested in enjoying fuel savings! We’re sorry that some customers may temporarily be experiencing an offer redemption issue, and bp is working on resolve the issue now',
    cta500: 'Atrás',
  },
  endOfRedemptionPeriod: {
    title: 'Los puntos PAYBACK han llegado a su fin',
    main: 'estamos trabajando en algo nuevo. ¡Espéralo!',
  },
};
