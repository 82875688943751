import { Persistence } from '../../persistence/Persistence';
import { FlowStepRuleFunction } from '../flow-engine';
import { APIContextActions } from '../../api/APIContext';

const excludedRedirects = ['/consent', '/welcome', '/home', '/landing', 'passcode-verify', '/end-of-redemption-period'];

export const rule: FlowStepRuleFunction = async function rule(_apiContext: APIContextActions) {
  const shouldAuthenticate = Persistence.getShouldAuthenticateAndRedirectToLastLocation();
  const lastLocation = Persistence.getLastLocation();
  const notExcluded = !excludedRedirects.includes(lastLocation!);
  const result = shouldAuthenticate && shouldAuthenticate === true && lastLocation! !== null && notExcluded;
  return result;
};
