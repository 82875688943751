import { ReactElement } from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useOAuthContext } from '../auth/OAuthContext';
import { useApplicationContext } from '../contexts/ApplicationContext';
import { Persistence } from '../persistence/Persistence';
import { getPartnerAuthenticationRoute } from './utils/private-route';

// export default withRouter(PrivateRoute);
interface PrivateRouteProps extends RouteProps {
  // tslint:disable-next-line:no-any
  component?: any;
  // tslint:disable-next-line:no-any
  children?: any;
}

const PrivateRoute = (props: PrivateRouteProps): ReactElement => {
  const { component: Component, children, ...rest } = props;

  const { currentPartner, currentPartnerRegion } = useApplicationContext();
  const { isAuthenticated } = useOAuthContext();

  const excludedRedirects = ['/landing', '/passcode-verify'];
  excludedRedirects.push(`/${currentPartner}/${currentPartnerRegion}/faq`);

  const notExcluded = !excludedRedirects.includes(props.location!.pathname);
  if (notExcluded) {
    const search = props.location?.search ? `/${props.location?.search}` : '';
    const lastLocation = `${props.location?.pathname}${search}`;
    Persistence.setLastLocation(lastLocation);
    Persistence.setShouldAuthenticateAndRedirectToLastLocation(true);
  }

  let redirectRoute = '401';

  if (currentPartner && currentPartnerRegion) {
    redirectRoute = getPartnerAuthenticationRoute(currentPartner, currentPartnerRegion);
  }

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isAuthenticated ? (
          Component ? (
            <Component {...routeProps} />
          ) : (
            children
          )
        ) : (
          <Redirect
            to={{
              pathname: redirectRoute,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
