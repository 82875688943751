import { ReactElement } from 'react';
import styles from '../Header.module.scss';
import { ReactComponent as BackArrow } from '../../../assets/images/icons/tail-left-32.svg';
import LanguageSelector from '../../LanguageSelector/LanguageSelector';

interface Props {
  backButton?: boolean;
  showBackButton?: boolean;
  currentPartnerRegion: string;
  currentPartner: string;
  program: string;
  languages: any;
  changeLanguage?: any;
  handleBack?: () => void;
}
const UberProHeader = ({
  backButton,
  showBackButton,
  currentPartnerRegion,
  currentPartner,
  program,
  languages,
  handleBack,
  changeLanguage,
}: Props): ReactElement => {
  return (
    <div data-testid="header" className={styles.header}>
      <div className={styles.headerLogo}>
        {(backButton || showBackButton) && (
          <button onClick={handleBack} data-testid="header__back-button" className={styles.back}>
            <BackArrow />
          </button>
        )}
        {currentPartnerRegion === 'us' && (
          <div className={styles.earnifyLogo}>
            <img alt="" src={`/images/${currentPartner}/${program}/earnify-us.svg`}></img>
          </div>
        )}

        {languages.length > 1 && (
          <LanguageSelector
            className={styles.language}
            partner={currentPartner}
            partnerRegion={currentPartnerRegion}
            onLanguageChange={changeLanguage}
          />
        )}
      </div>
      <div className={styles.logos}>
        <img
          data-testid="header-logo-primary"
          alt="Primary logo"
          src={`/images/${currentPartner}/${program}/bp-${currentPartnerRegion}.svg`}
        ></img>
        <span className={styles.separator}></span>
        <img
          className={styles.partnerLogo}
          data-testid="header-logo-secondary"
          alt="Secondary logo"
          src={`/images/${currentPartner}/${program}/partner-logo.svg`}
        ></img>
      </div>
    </div>
  );
};

export default UberProHeader;
