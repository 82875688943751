import validatePhonePasscodeCIPMutation from 'graphql-tag';

export default validatePhonePasscodeCIPMutation`
mutation validatePhonePasscode($phoneInput: PhoneInput!, $passcode: String!, $passcodeSessionId: String) {
    validatePhonePasscode(phoneInput: $phoneInput, passcode: $passcode, passcodeSessionId: $passcodeSessionId) {
      countryCode
      phoneNumber
      region
      userId
      passcodeSessionId
    }
  }
`;
