import { FlowOptions, FlowStepRuleAction } from '../flow-engine';
import history from '../../routing/BrowserHistory';
import { APIContextActions } from '../../api/APIContext';
import { Persistence } from '../../persistence/Persistence';
import { getErrorMessage } from '../../helpers/errorMessage';
import { createLoyaltyWithPolling } from '../../hooks/useSetLoyaltyInfoWithPolling';
import { ILoyaltyInfo } from '../../user/ILoyaltyInfo';

export async function action(
  apiContext: APIContextActions,
  flowOptions: Partial<FlowOptions>,
): Promise<ILoyaltyInfo | undefined> {
  try {
    const { idToken, accessToken, user, currentPartner, currentPartnerRegion, currentLoyaltyType } = flowOptions;

    const loyaltyId = currentPartnerRegion === 'us' ? (user?.phone || '').replace('+1', '').replace(/\s/g, '') : '';
    const userId = user?.userId ?? '';
    const firstName = user?.firstName ?? '';
    const lastName = user?.lastName ?? '';
    const email = user?.attributes?.email;
    const phone = user?.phone;

    const res = await createLoyaltyWithPolling({
      idToken: idToken || '',
      accessToken: accessToken || '',
      loyaltyId,
      loyaltyType: (currentLoyaltyType || '').toLowerCase(),
      partnerType: (currentPartner || '').toLowerCase(),
      partnerLevelId: Persistence.getPartnerLevelId() || 'TIER_1',
      partnerRegion: (currentPartnerRegion || '').toLowerCase(),
      userId,
      firstName,
      lastName,
      email,
      phone,
      linkLoyaltyInfo: apiContext['linkLoyaltyInfo'],
      pollLoyaltyInfo: apiContext['pollLoyaltyInfo'],
    });

    return res;
  } catch (error: unknown) {
    const errorWithMessage = getErrorMessage(error);
    throw new Error(`Flow action failure: "${errorWithMessage.message}"`);
  }
}

export const completion = (_flowStepRuleAction: FlowStepRuleAction, result: ILoyaltyInfo | undefined): void => {
  if (result?.loyaltyId) {
    history.replace('/home');
    return;
  }

  history.replace('/welcome');
};
