import * as localeCodes from 'locale-codes';
import i18n from './../localisation/i18n';

export type LocaleType = {
  name?: string;
  language?: string;
  locale?: string;
  region?: string;
  defaultLanguage: string;
};

const localesTagsPartnerRegion: { [key: string]: string } = {
  us: 'en-US',
  nl: 'nl',
  es: 'es',
  uk: 'en-GB',
};

/**
 * Locale parser for given string.
 * @param value  Value to parse nl-Nl, nl, ....
 * @returns      LocaleType with defaultLanguage if unable to parse.
 */
const getLocale = (partnerRegion: string): LocaleType => {
  const tag: string = localesTagsPartnerRegion[partnerRegion];
  const localeTag = localeCodes.getByTag(tag);

  //   logger.info({ ...localeTag });
  const locale: LocaleType = {
    defaultLanguage: i18n.language,
  };

  if (
    localeTag &&
    localeTag !== undefined &&
    i18n.options.supportedLngs &&
    i18n.options.supportedLngs.filter((lng) => {
      return lng === localeTag['iso639-1'];
    }).length > 0
  ) {
    locale.name = localeTag.name;
    locale.language = localeTag['iso639-1'];
    locale.locale = localeTag.tag;
    locale.region = localeTag.location;
  } else {
    const defaultLocaleTag = localeCodes.getByTag(i18n.language);
    locale.name = defaultLocaleTag.name;
    locale.language = defaultLocaleTag['iso639-1'];
    locale.locale = defaultLocaleTag.tag;
    locale.region = defaultLocaleTag.location;
  }

  // logger.info({ ...locale });
  return locale;
};

export default getLocale;
