import { lazy, ReactElement } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getPartnerRegionConfig, getSupportedPartners } from '../helpers/PartnerRegionPropertySelector';
import PrivacyPolicySelector from '../views/privacy-policy/PrivacyPolicySelector';
import TermsAndConditionsSelector from '../views/terms-and-conditions/TermsAndConditionsSelector';
import { IAppRouterProps } from './RouterComponentSwitcher';

/* istanbul ignore next: No need to codecov this test import */
const TermsAndConditions = lazy(() => import('../views/terms-and-conditions/TermsAndConditions'));
/* istanbul ignore next: No need to codecov this import */
const PrivacyPolicy = lazy(() => import('../views/privacy-policy/PrivacyPolicy'));

/* istanbul ignore next: No need to codecov */
export const PartnerRegionSpecificRoutes = (props: IAppRouterProps): ReactElement[] => {
  let routes: ReactElement[] = [];
  props.partnerRegionRouteConfigs.forEach((routeConfig) => {
    const partnerRegionConfig = getPartnerRegionConfig(routeConfig.partner, routeConfig.partnerRegion);
    const supportedPrivacyVersions = partnerRegionConfig.consents?.supportedPrivacyVersions;
    const latestPrivacyVersion = supportedPrivacyVersions[supportedPrivacyVersions.length - 1];
    const supportedTermsVersions = partnerRegionConfig.consents?.supportedTermsVersions;
    const latestTermsVersions = supportedPrivacyVersions[supportedTermsVersions.length - 1];

    supportedPrivacyVersions.forEach((privacyVersion) => {
      routes.push(
        <Route
          key={`/${routeConfig.partner}/${routeConfig.partnerRegion}/privacy/${privacyVersion}`}
          path={`/${routeConfig.partner}/${routeConfig.partnerRegion}/privacy/${privacyVersion}`}
          render={(props) => (
            <PrivacyPolicy
              {...props}
              partner={routeConfig.partner}
              partnerRegion={routeConfig.partnerRegion}
              version={privacyVersion}
            />
          )}
          exact={true}
        />,
      );
    });

    supportedTermsVersions.forEach((termsVersion) => {
      routes.push(
        <Route
          key={`/${routeConfig.partner}/${routeConfig.partnerRegion}/terms/${termsVersion}`}
          path={`/${routeConfig.partner}/${routeConfig.partnerRegion}/terms/${termsVersion}`}
          render={(props) => (
            <TermsAndConditions
              {...props}
              partner={routeConfig.partner}
              partnerRegion={routeConfig.partnerRegion}
              version={termsVersion}
            />
          )}
          exact={true}
        />,
      );
    });

    routes = routes.concat([
      <Redirect
        key={`/${routeConfig.partner}/${routeConfig.partnerRegion}/terms/latest`}
        from={`/${routeConfig.partner}/${routeConfig.partnerRegion}/terms/latest`}
        to={`/${routeConfig.partner}/${routeConfig.partnerRegion}/terms/${latestTermsVersions}`}
        exact={true}
      />,
      <Redirect
        key={`/${routeConfig.partner}/${routeConfig.partnerRegion}/privacy/latest/`}
        from={`/${routeConfig.partner}/${routeConfig.partnerRegion}/privacy/latest/`}
        to={`/${routeConfig.partner}/${routeConfig.partnerRegion}/privacy/${latestPrivacyVersion}`}
        exact={true}
      />,
    ]);
  });

  const supportedPartners = getSupportedPartners();
  supportedPartners.forEach((partner) => {
    routes = routes.concat([
      <Route
        key={`/${partner}/terms`}
        path={`/${partner}/terms`}
        render={(props) => <TermsAndConditionsSelector {...props} partner={partner} />}
        exact={true}
      />,
      <Route
        key={`/${partner}/privacy`}
        path={`/${partner}/privacy`}
        render={(props) => <PrivacyPolicySelector {...props} partner={partner} />}
        exact={true}
      />,
    ]);
  });

  return routes;
};
