import { Persistence } from '../../persistence/Persistence';
import { validOrEmptyPhoneByRegion } from '../../utils/user';
import { FlowStepRuleFunctionVariadic } from '../flow-engine';

export const rule: FlowStepRuleFunctionVariadic = async function rule(_apiContext, flowOptions) {
  const { currentPartner: partner, currentPartnerRegion: region, user } = flowOptions;

  if (partner === 'uber-pro' && region === 'us') {
    // US *needs* to have a phone number verified.
    if (user?.phoneNumberVerified !== true) {
      return true;
    }
  }

  const loyaltyId = user?.loyaltyInfo?.loyaltyId || Persistence.getLoyaltyId();

  if (loyaltyId) {
    return false;
  }

  if (!validOrEmptyPhoneByRegion(partner, user?.phone) || !user?.phoneNumberVerified) {
    return true;
  }

  return false;
};
