import getLoyaltyBalanceQuery from 'graphql-tag';
import { ILoyaltyBalance } from '../../../user/ILoyaltyBalance';

export interface IGetLoyaltyBalanceResult {
  loyaltyBalance: ILoyaltyBalance;
}

export default getLoyaltyBalanceQuery`
query getLoyaltyBalanceQuery($partnerType: String!, $loyaltyType: String!, $partnerRegion: String!){
  loyaltyBalance(partnerType: $partnerType, loyaltyType: $loyaltyType, partnerRegion: $partnerRegion){
    currentPointsBalance,
    currencyValue,
    currencySymbol
  }
}
`;
