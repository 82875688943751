import { ReactElement } from 'react';
import { Route } from 'react-router-dom';
import { CipLoginRedirector } from './CipLoginRedirector';
import { IAppRouterProps } from './RouterComponentSwitcher';

/* istanbul ignore next: No need to codecov*/
export const LoginRoutes = (props: IAppRouterProps): ReactElement[] => {
  let routes: ReactElement[] = [];

  props.partnerRegionRouteConfigs.forEach((routeConfig) => {
    const partner = routeConfig.partner;
    const partnerRegion = routeConfig.partnerRegion;
    // Build path for the end-user to hit, e.g. /uber-pro/nl
    const authEntryPath = `/${partner}/${partnerRegion}/`;
    // Build path to continue web server flow, e.g. /auth/uber-pro/webserverflow
    // note: pageName is not given on the web server auth flow route, because we log the analytics
    // on the first implicit auth flow redirect. Please keep the pageName as 'Login'

    routes = routes.concat([
      <Route key={`initiate-oauth-cip-flow-${partner}-${partnerRegion}`} path={authEntryPath} exact={true}>
        <CipLoginRedirector partner={partner} partnerRegion={partnerRegion} />
      </Route>,
    ]);
  });

  return routes;
};
