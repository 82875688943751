import jwtdecode from 'jwt-decode';

export interface JWTHeader {
  kid: string;
  typ: string;
  alg: string;
}

export interface JWTPayload {
  at_hash: string;
  sub: string;
  aud: string;
  iss: string;
  exp: number;
  iat: number;
  nonce: string;
}

/**
 * Custom attributes specific to BP SF
 * Consent info related to tc and pp.
 */
export interface SFJWTPayLoad extends JWTPayload {
  custom_attributes?: any;
  family_name?: string;
  given_name?: string;
  email?: string;
}

export interface CIPJWTPayload extends JWTPayload {
  external_id: string;
  socialAccessToken: string;
  socialRefreshToken: string;
}

export function decodeToken<T = SFJWTPayLoad>(idToken: string): T | null {
  try {
    const decodedTokenPayload = jwtdecode<T>(idToken, { header: false });

    return decodedTokenPayload;
  } catch (_error) {
    return null;
  }
}

export function isTokenExpired(idToken: string): boolean {
  try {
    const decodedTokenPayload = jwtdecode<SFJWTPayLoad>(idToken, { header: false });
    /* istanbul ignore next: No need to codecov */
    if (decodedTokenPayload === undefined) return true;
    const current_time = new Date().getTime() / 1000;
    /* istanbul ignore if: No need to codecov */
    if (current_time > decodedTokenPayload.exp) {
      return true;
    }
    return false;
  } catch (_error) {
    return true;
  }
}

export function isDecodableJWT(jwt: string): boolean {
  try {
    const decodedTokenHeader = jwtdecode<JWTHeader>(jwt, { header: true });
    const decodedTokenPayload = jwtdecode<JWTPayload>(jwt, { header: false });
    return decodedTokenHeader != null && decodedTokenPayload != null;
  } catch (error) {
    return false;
  }
}
