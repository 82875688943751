import { CIPConfig, Partner } from '../../../../interfaces/Partner';
import { cipTokenStore } from '../../../../helpers/cipTokenStore';

const getCipConfig = (region: string): CIPConfig => ({
  clientId: `marvel-${region}-web`,
  redirectUri: 'https://reward.bp.com/auth/callback',
  scope: 'profile email phone b2c-contact b2c-consent b2c-profile openid',
  serverConfig: {
    baseUrl: 'https://energyid.bp.com/am/',
    timeout: 3000,
  },
  realmPath: 'bravo',
  middleware: [],
  tokenStore: cipTokenStore,
});

const getAmazonCipConfig = (region: string): CIPConfig => ({
  clientId: `amazon-${region}-web`,
  redirectUri: 'https://reward.bp.com/auth/callback',
  scope: 'profile email phone b2c-contact b2c-consent b2c-profile openid',
  serverConfig: {
    baseUrl: 'https://energyid.bp.com/am/',
    timeout: 3000,
  },
  realmPath: 'bravo',
  middleware: [],
  tokenStore: cipTokenStore,
});

export default [
  {
    partnerType: 'uber-pro',
    regions: [
      {
        companyName: 'bp Europa SE',
        consents: {
          currentPrivacyPolicyVersion: '1.0',
          currentTandCVersion: '1.0',
          isSmsMarketingAutoChecked: false,
          supportedMarketingChannels: ['Email'],
          supportedPrivacyVersions: ['v1'],
          supportedTermsVersions: ['v1'],
        },
        contactEmail: 'consumentenservice@bp.com',
        copyright: {
          endYear: '2022',
          startYear: '1996',
        },
        defaultLanguage: 'nl',
        cipConfig: getCipConfig('nl'),
        loyaltyType: 'freebees',
        regionDisplayName: 'Nederland',
        regionName: 'nl',
        supportedLanguages: ['nl', 'en'],
      },
      {
        companyName: 'bp Products North America',
        consents: {
          additionalConsentsRequired: ['uber-pro-ridehailing'],
          currentPrivacyPolicyVersion: '1.0',
          currentTandCVersion: '1.0',
          isSmsMarketingAutoChecked: false,
          isEmailMarketingAutoChecked: true,
          supportedMarketingChannels: ['Email', 'SMS'],
          supportedPrivacyVersions: ['v1'],
          supportedTermsVersions: ['v1'],
        },
        contactEmail: 'bpconsumer@bp.com',
        copyright: {
          endYear: '2024',
          startYear: '2016',
        },
        defaultLanguage: 'en',
        cipConfig: getCipConfig('us'),
        loyaltyType: 'bpme-rewards-us',
        regionDisplayName: 'USA',
        regionName: 'us',
        supportedLanguages: ['en'],
      },
      {
        companyName: 'bp Mexico',
        consents: {
          additionalConsentsRequired: ['uber-pro-ridehailing'],
          currentPrivacyPolicyVersion: '1.0',
          currentTandCVersion: '1.0',
          isSmsMarketingAutoChecked: false,
          supportedMarketingChannels: ['Email', 'SMS'],
          supportedPrivacyVersions: ['v1'],
          supportedTermsVersions: ['v1'],
        },
        contactEmail: 'contactanos@bp.com',
        copyright: {
          endYear: '2022',
          startYear: '1996',
        },
        defaultLanguage: 'es',
        cipConfig: getCipConfig('mx'),
        loyaltyType: 'payback-mx',
        regionDisplayName: 'M\u00e9xico',
        regionName: 'mx',
        supportedLanguages: ['es', 'en'],
      },
      {
        companyName: 'bp UK',
        consents: {
          additionalConsentsRequired: ['uber-pro-ridehailing'],
          currentPrivacyPolicyVersion: '2.0',
          currentTandCVersion: '2.0',
          isSmsMarketingAutoChecked: false,
          isEmailMarketingAutoChecked: true,
          supportedMarketingChannels: ['Email'],
          supportedPrivacyVersions: ['v2'],
          supportedTermsVersions: ['v2'],
        },
        contactEmail: 'https://www.bpmerewards.co.uk/contact-us/',
        copyright: {
          endYear: '2022',
          startYear: '2016',
        },
        defaultLanguage: 'en',
        cipConfig: getCipConfig('uk'),
        loyaltyType: 'bpme-rewards-uk',
        regionDisplayName: 'UK',
        regionName: 'uk',
        supportedLanguages: ['en'],
      },
      {
        companyName: 'bp AU',
        consents: {
          additionalConsentsRequired: ['uber-pro-ridehailing'],
          currentPrivacyPolicyVersion: '2.0',
          currentTandCVersion: '2.0',
          isSmsMarketingAutoChecked: true,
          isEmailMarketingAutoChecked: true,
          supportedMarketingChannels: ['Email', 'SMS'],
          supportedPrivacyVersions: ['v2'],
          supportedTermsVersions: ['v2'],
        },
        contactEmail: 'https://bpcustomers.secure.force.com/gcmcontactus',
        copyright: {
          endYear: '2022',
          startYear: '2016',
        },
        defaultLanguage: 'en',
        cipConfig: getCipConfig('au'),
        loyaltyType: 'bpme-rewards-au',
        regionDisplayName: 'AU',
        regionName: 'au',
        supportedLanguages: ['en'],
        unlockedStoreFinderVideo: {
          partnerId: 895641,
          uiConfId: 42266921,
          videoId: '1_80wtsh57',
          videoLength: '2.23',
          videoRatio: 56.25,
        },
      },
    ],
  },
  {
    partnerType: 'amazon-prime',
    regions: [
      {
        companyName: 'bp Products North America',
        consents: {
          additionalConsentsRequired: [''],
          currentPrivacyPolicyVersion: '1.0',
          currentTandCVersion: '1.0',
          isSmsMarketingAutoChecked: false,
          isEmailMarketingAutoChecked: true,
          supportedMarketingChannels: ['Email', 'SMS'],
          supportedPrivacyVersions: ['v1'],
          supportedTermsVersions: ['v1'],
        },
        contactEmail: 'bpconsumer@bp.com',
        copyright: {
          endYear: '2024',
          startYear: '2016',
        },
        defaultLanguage: 'en',
        cipConfig: getAmazonCipConfig('us'),
        loyaltyType: 'bpme-rewards-us',
        regionDisplayName: 'USA',
        regionName: 'us',
        supportedLanguages: ['en'],
      },
    ],
  },
] satisfies Partner[];
