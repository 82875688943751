import { ReactElement, useEffect, useState, MouseEvent } from 'react';
import { Dropdown } from 'react-bootstrap';
import { IAppCallBackProps } from '../../routing/RouterComponentSwitcher';

import { ReactComponent as CaretDown } from '../../assets/images/icons/down-large-24.svg';
import { DropdownItemProps } from 'react-bootstrap/esm/DropdownItem';
import { Trans, useTranslation } from 'react-i18next';
import { getSupportedLanguages } from '../../helpers/PartnerRegionPropertySelector';
import { useApplicationContext } from '../../contexts/ApplicationContext';

interface ILanguageSelectorProps extends IAppCallBackProps {
  partner: string;
  partnerRegion?: string;
  onLanguageChange(language: string): void;
  className?: string;
}

const LanguageSelector = (props: ILanguageSelectorProps): ReactElement => {
  const { t } = useTranslation('languageSelector');
  const supportedPartnerLanguages = getSupportedLanguages(props.partner, props.partnerRegion).sort((ln1, ln2) => {
    return t(ln1).toLowerCase().localeCompare(t(ln2).toLowerCase());
  });

  const { currentLanguage } = useApplicationContext();
  const [currentSelectedLanguage, setCurrentSelectedLanguage] = useState(!currentLanguage ? 'en' : currentLanguage);
  const [supportedLanguages, setSupportedLanguages] = useState(supportedPartnerLanguages);

  const _handleLanguageChange = (language: string, e?: MouseEvent<DropdownItemProps>) => {
    e?.preventDefault();
    setCurrentSelectedLanguage(language);
    props.onLanguageChange(language);
  };

  useEffect(() => {
    const orderedSupportedLangugage = [...supportedPartnerLanguages].sort((ln1) =>
      ln1 === currentSelectedLanguage ? -1 : 1,
    );
    setSupportedLanguages(orderedSupportedLangugage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  return (
    <Dropdown className={props.className}>
      <Dropdown.Toggle variant="languageSelector" size="sm" data-testid="language-selector">
        <span data-testid="language-selector-text" className="language-selector-text">
          <Trans i18nKey={'languageSelector:' + currentSelectedLanguage}>{currentSelectedLanguage}</Trans>
        </span>
        <CaretDown className="caretDown" data-testid="language-selector-caret-down" />
      </Dropdown.Toggle>
      <Dropdown.Menu data-testid="language-selector-menu">
        {supportedLanguages.map((language) => (
          // eslint-disable-next-line react/jsx-key
          <Dropdown.Item
            onClick={(e) => _handleLanguageChange(language, e)}
            active={language.toLowerCase() === currentSelectedLanguage.toLowerCase() ? true : false}
            data-testid={'language-selector-menu-item-' + language}
            key={'language-selector-menu-item-' + language}
          >
            {t(language)}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSelector;
