import { lazy, ReactElement } from 'react';
import { Route } from 'react-router-dom';

/* istanbul ignore next: No need to codecov this test import */
const TestingView = lazy(() => import('../views/testing/Testing'));
const LogoutView = lazy(() => import('../views/testing/Logout'));

/* istanbul ignore next: No need to codecov this test routes function */
export const TestRoutes = (): ReactElement[] | null => {
  // if (!isDev) return null;
  return [
    <Route key="test1" exact={true} path="/test1" component={TestingView} />,
    <Route key="test2" exact={true} path="/test2" component={TestingView} />,
    <Route key="test3" exact={true} path="/test3" component={TestingView} />,
    <Route key="test/logout" exact={true} path="/test/logout" component={LogoutView} />,
  ];
};
