import { Region } from '../../interfaces/Region';
import { IUser } from '../../user/IUser';
import { Persistence } from '../../persistence/Persistence';
import { FlowOptions, FlowStepRuleFunctionVariadic } from '../flow-engine';
import { Consent } from '../../user/IUserPii';

export const getConsentPrefix = ({ multiRegion, appShortName }: Region): string =>
  multiRegion ? `${appShortName}-` : '';

export const getAcceptedTc = (user: IUser, partnerRegionConfig: Region): string | undefined => {
  if (user.consents?.length) {
    return user.consents.find((f: Consent) => f.consentType === 'Terms and Conditions' && f.accepted)?.version;
  }
  return user?.attributes?.[`${getConsentPrefix(partnerRegionConfig)}tc-accepted`];
};

export const getAcceptedPp = (user: IUser, partnerRegionConfig: Region): string | undefined => {
  if (user.consents?.length) {
    return user.consents.find((f: Consent) => f.consentType === 'Privacy Policy' && f.accepted)?.version;
  }
  return user?.attributes?.[`${getConsentPrefix(partnerRegionConfig)}pp-accepted`];
};

export const getLatestTc = (partnerRegionConfig: Region): string | undefined => {
  return partnerRegionConfig.consents.currentTandCVersion;
};

export const getLatestPp = (partnerRegionConfig: Region): string | undefined => {
  return partnerRegionConfig.consents.currentPrivacyPolicyVersion;
};

const needsToAcceptTAndC = (user: IUser, partnerRegionConfig: Region): boolean => {
  const accepted = getAcceptedTc(user, partnerRegionConfig);
  const latest = getLatestTc(partnerRegionConfig);
  return accepted !== latest;
};

const needsToAcceptPP = (user: IUser, partnerRegionConfig: Region): boolean => {
  const accepted = getAcceptedPp(user, partnerRegionConfig);
  const latest = getLatestPp(partnerRegionConfig);
  return accepted !== latest;
};

const needsToAcknowledgeGeneralMarketingPreference = (user: IUser, partnerRegionConfig: Region): boolean => {
  if (user.consents?.length) {
    return !user.consents.find((f: Consent) => f.consentType === 'General Marketing' && f.accepted);
  }

  return user?.attributes?.[`${getConsentPrefix(partnerRegionConfig)}general-marketing`] === undefined;
};

export const rule: FlowStepRuleFunctionVariadic = async function rule(_apiContext, flowOptions: Partial<FlowOptions>) {
  const { user, region: partnerRegionConfig } = flowOptions;

  if (Persistence.getConsentCheckOverrideStatus()) {
    return false;
  }

  const hasToAcceptTAndC = needsToAcceptTAndC(user!, partnerRegionConfig!);
  const hasToAcceptPP = needsToAcceptPP(user!, partnerRegionConfig!);

  const result =
    hasToAcceptTAndC || hasToAcceptPP || needsToAcknowledgeGeneralMarketingPreference(user!, partnerRegionConfig!);

  return result;
};
