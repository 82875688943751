import patchUserMutation from 'graphql-tag';

export default patchUserMutation`
mutation patchUserV2($userInput: UserInput!) {
  patchUserV2(userInput: $userInput){
    consent {
      termsVersion
      privacyVersion
      marketingChannels
      isConsentUpdate
    }
  }
}
`;

export type UserInput = {
  consentInput: ConsentInput;
  accessToken?: string;
};

export type ConsentInput = {
  partnerType: string;
  region: string;
  termsVersion: string;
  privacyVersion: string;
  marketingChannels: string[];
  isConsentUpdate: boolean;
};
