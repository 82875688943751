import { useEffect, useState } from 'react';
import { useApplicationContext } from '../../contexts/ApplicationContext';
import { Theme } from './Theme';
import { getTheme } from './helper';
import { PartnerType } from '../../interfaces/Partner';
import { useAtomValue } from 'jotai';
import { programAtom } from '../../store';

export const useTheme = (): Theme => {
  const program = useAtomValue(programAtom);
  const { currentPartner, currentPartnerRegion } = useApplicationContext();

  const [theme, setTheme] = useState<Theme>();

  useEffect(() => {
    const theme = getTheme(currentPartner as PartnerType, program, currentPartnerRegion);
    setTheme(theme);
  }, [currentPartner, program, currentPartnerRegion]);

  return theme!;
};
