import query from 'graphql-tag';

import { IUser } from '../../../user/IUser';
import { IUserPii } from '../../../user/IUserPii';

export interface IGetUserResult {
  userPii: IUserPii;
  user: IUser;
}

export const getLoyaltyInfoQuery = query`
  query getLoyaltyInfoData($partnerType: String!, $loyaltyType: String!, $partnerRegion: String!){
    user(partnerType: $partnerType, loyaltyType: $loyaltyType, partnerRegion: $partnerRegion) {
      loyaltyInfo {
        loyaltyId
        loyaltyIdBarCode
      }
    }
  }
`;

export const getUserCipQuery = query`
query getUserDataCip($partnerType: String!, $loyaltyType: String!, $partnerRegion: String!, $userId: String!, $accessToken: String){
  userPii(userId: $userId, accessToken: $accessToken){
    firstName
    lastName
    phone
    phoneVerified
    email
    preferredAuthMethod
    consents {
      consentId
      acceptedTime
      accepted
      version
      identityExternalId
      consentType
      channel
      appName
    }
  }
  user(partnerType: $partnerType, loyaltyType: $loyaltyType, partnerRegion: $partnerRegion) {
    loyaltyInfo {
      loyaltyId
      loyaltyIdBarCode
    }
    partnerInfo {
      partnerLevel
      partnerLevelId
    }
  }
}
`;

const getUserQuery = query`
query getUserData($partnerType: String!, $loyaltyType: String!, $partnerRegion: String!, $userId: String!, $accessToken: String){
  userPii(userId: $userId, accessToken: $accessToken){
    firstName
    phone
    phoneVerified
    preferredAuthMethod
  }
  user(partnerType: $partnerType, loyaltyType: $loyaltyType, partnerRegion: $partnerRegion) {
    loyaltyInfo {
      loyaltyId
      loyaltyIdBarCode
    }
    partnerInfo {
      partnerLevel
      partnerLevelId
    }
  }
}
`;

export default getUserQuery;

export const getPartnerStatusQuery = query`
query getPartnerStatus($partnerStatusInformation: PartnerStatusInformation){
  partnerStatus(partnerStatusInformation: $partnerStatusInformation) {
    isActive
  }
}
`;

export function isGetUserResult(result: IGetUserResult): result is IGetUserResult {
  return result && result.user !== undefined;
}
