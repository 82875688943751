export default {
  default: {
    loading: 'Bezig met laden',
    redirecting: 'Omleiden',
    processing: 'Verwerken',
  },
  title: {
    uberproNL: 'bp Reward | Meer voordeel met Uber Pro',
    uberproUS: 'bp Reward | Meer voordeel met Uber Pro',
  },
  termsRegionSelector: {
    instructions: 'Selecteer je locatie:',
    title: 'Algemene voorwaarden',
  },
  termsNLv1: {
    back: 'Terug',
    description: 'Terms and conditions for the Netherlands',
    title: 'Algemene voorwaarden',
  },
  privacyRegionSelector: {
    instructions: 'Selecteer je locatie:',
    title: 'Informatie over de controller',
  },
  privacyNLv1: {
    back: 'Terug',
    description: 'Privacy Policy for the Netherlands',
    title: 'Informatie over de controller',
  },
  faqNL: {
    back: 'Terug',
    description: 'FAQ for the Netherlands',
    title: 'Veelgestelde vragen',
  },
  faqUS: {
    back: 'Terug',
    content: '',
    description: 'FAQ for the USA',
    title: 'Veelgestelde vragen',
  },
  home: {
    points: 'FreeBees',
    point: 'FreeBees',
    youHave: 'Je hebt',
    barcodeInstructions:
      // === available params ===
      // {{loyaltyType}}
      'Toon je barcode bij elke transactie. Je extra {{loyaltyType}} worden automatisch bijgeschreven.',
    linkExplanation:
      // === available params ===
      // {{loyaltyType}}
      // {{partnerType}}
      // {{partnerLevel}}
      'Je {{loyaltyType}}-account is gekoppeld aan {{partnerType}}.  Als {{partnerType}} deelnemer met {{partnerLevel}} status, spaar je extra {{loyaltyType}}.',
  },
  bpMeRewardsWelcome: {
    greeting:
      // === available params ===
      // {{firstName}}
      'Hallo{{firstName}}',
  },
  footer: {
    // === available params ===
    // {{startYear}}
    // {{endYear}}
    copyright: 'Copyright © {{startYear}}-{{endYear}}',
    linkFAQ:
      // === indexes ===
      // 0 = Link to FAQ
      '<0>Veelgestelde vragen</0>',
    linkTermsAndConditions:
      // === indexes ===
      // 0 = Link to privacy statement
      '<0>Voorwaarden</0>',
    linkPrivacyStatement:
      // === indexes ===
      // 0 = Link to privacy statement
      '<0>Privacyverklaring</0>',
    linkContactUs:
      // === indexes ===
      // 0 = Link to contact us
      '<0>Neem contact met ons op</0>',
  },
  welcome: {
    heading: 'Welkom',
    intro:
      // === available params ===
      // {{partnerType}}
      // {{loyaltyType}}
      // {{partnerLevel]]}
      'Bedankt voor het koppelen van je {{partnerType}} account. Als {{partnerType}} deelnemer met {{partnerLevel}} status kun je nu extra {{loyaltyType}} gaan sparen. Voltooi je aanmelding door je {{loyaltyType}}-kaart te linken.',
    partnerCta:
      // === available params ===
      // {{loyaltyCardType}}
      'Voeg je {{loyaltyCardType}}-kaart toe',
    partnerHeading:
      // === available params ===
      // {{loyaltyCardType}}
      'Nog geen {{loyaltyCardType}}-spaarder?',
    partnerPromo:
      // === available params ===
      // {{loyaltyCardType}}
      // {{loyaltyCardLinkDomain}}
      // === indexes ===
      // 2 = Link to sign up
      'Haal een {{loyaltyCardType}}-kaart op bij een bemand bp station of maak direct een digitale kaart aan. ' +
      'Kom vervolgens terug op deze pagina om je kaart toe te voegen. <br /><br />' +
      '<2>Maak een digitale {{loyaltyCardType}}-kaart aan</2>',
  },
  linkFreebeesCard: {
    title:
      // === available params ===
      // {{loyaltyCardType}}
      'Koppel je {{loyaltyCardType}}-kaart',
    requirements: 'We hebben alleen je kaartnummer en wachtwoord nodig.',
    labelCardNumber:
      // === available params ===
      // {{loyaltyCardType}}
      '{{loyaltyCardType}}-kaartnummer',
    labelCardNumberLocation:
      // === available params ===
      // {{loyaltyCardType}}
      'Deze vind je op je {{loyaltyCardType}}-kaart of wanneer je inlogt op je {{loyaltyCardType}}-account',
    labelCardNumberError:
      // === available params ===
      // {{loyaltyCardType}}
      'Voeg je 14-cijferig {{loyaltyCardType}}-nummer toe',
    labelPassword:
      // === available params ===
      // {{loyaltyCardType}}
      '{{loyaltyCardType}}-wachtwoord',
    labelPasswordForgot:
      // === available params ===
      // {{forgottenPasswordLinkDomain}}
      // === indexes ===
      // 2 = Link to forgotten password
      'Wachtwoord vergeten? Klik dan <2>hier</2>',
    physicalCardNoPass:
      // === available params ===
      // {{loyaltyCardType}}
      // {{registrationLinkDomain}}
      // === indexes ===
      // 1 = Link to register account
      'Nog geen wachtwoord voor je fysieke {{loyaltyCardType}}-kaart? Registreer hem dan via <1>{{registrationLinkDomain}}</1>.',
    labelPasswordError: 'Gebruik een geldig wachtwoord',
    buttonConfirm: 'Bevestigen',
    buttonCancel: 'Annuleren',
    submissionErrorTitle: 'We konden je FreeBees-kaart niet linken',
    submissionErrorDetails: 'Kijk of je kaartnummer en wachtwoord kloppen en probeer het opnieuw.',
  },
  tandcUS: {
    reviewPolicies:
      // === indexes ===
      // 1 = Link to terms and conditions
      // 3 = Link to privacy policy
      'DU To receive bp’s offer and to use BPme Rewards, you’ll need to review and accept our Terms and Conditions <1>Terms and Conditions</1> and read our Privacy Statement <3>Privacy Statement</3>.',
    labelTandC: 'DU I agree to the Terms and Conditions and I have read and understood the Privacy Statement',
    marketingSectionHeader:
      'DU Keep up to date with new offers and the latest news so that you can get the most out of BPme Rewards.',
    emailMarketingTitle: 'DU Email me about rewards and updates',
    smsMarketingTitle: 'DU Text me about rewards and updates',
    smsMarketingSubTitle:
      'DU Recurring automated marketing messages will be sent to the mobile number provided at opt in. Consent is not a condition of purchase. Msg and data rates may apply. Text STOP to quit and HELP for help. ',
  },
  tandcNL: {
    reviewPolicies:
      // === indexes ===
      // 1 = Link to terms and conditions
      // 3 = Link to privacy policy
      'Om je registratie compleet te maken dien je de <1>voorwaarden</1> te accepteren en ons te lezen <3>privacy beleid</3>',
    labelTandC: 'Ik accepteer de voorwaarden',
    marketingSectionHeader: 'Als je marketing communicatie wilt ontvangen, plaats dan een vinkje.',
    emailMarketingTitle: 'Ik ontvang graag bp marketing communicatie',
    emailMarketingSubTitle: '',
    nameLabel: 'Naam',
    lastNameLabel: 'Achternaam',
    acceptError: 'Accepteer de algemene voorwaarden',
    emailLabel: 'Email',
    registrationTitle: 'Registeren',
    registrationHelperText:
      'Wij zullen uw BPme rewards account opzetten met de informatie die we hebben ontvangen vanuit xxx.',
    smsMarketingTitle: '',
    smsMarketingSubTitle: '',
    buttonSubmit: 'Bevestig deelname',
    title: 'Voorwaarden',
  },
  tandcMX: {
    reviewPolicies:
      // === indexes ===
      // 1 = Link to terms and conditions
      // 3 = Link to privacy policy
      'To receive bp’s offer and to use BPme Rewards, you’ll need to review and accept our Terms and Conditions <1>Terms and Conditions</1> and read our Privacy Statement <3>Privacy Statement</3>.',
    labelTandC: 'I agree to the Terms and Conditions and I have read and understood the Privacy Statement',
    marketingSectionHeader:
      'Keep up to date with new offers and the latest news so that you can get the most out of BPme Rewards.',
    emailMarketingTitle: 'Email me about rewards and updates',
    smsMarketingTitle: 'Text me about rewards and updates',
    smsMarketingSubTitle:
      'Recurring automated marketing messages will be sent to the mobile number provided at opt in. Consent is not a condition of purchase. Msg and data rates may apply. Text STOP to quit and HELP for help. ',
    buttonSubmit: 'Bevestig deelname',
  },
  tandcGlobal: {
    title: 'Bijna klaar',
    reviewPolicies:
      // === indexes ===
      // 1 = Link to terms and conditions
      // 3 = Link to privacy policy
      'Om je registratie compleet te maken dien je de <1>voorwaarden</1> te accepteren en ons te lezen <3>privacy beleid</3>',
    labelTandC: 'Ik accepteer de voorwaarden',
    acceptAgreement: 'Door een account aan te maken ga je akkoord met de voorwaarden van bp.',
    labelMarketing: 'Ik ontvang graag bp marketing communicatie',
    agreeMarketing: 'Als je marketing communicatie wilt ontvangen, plaats dan een vinkje.',
  },
  languageSelector: {
    en: 'Engels (English)',
    nl: 'Nederlands',
    es: 'Spaans (Spanish)',
  },
  error: {
    title401: '401 fout',
    heading401: 'Je hebt geen toegang tot deze pagina',
    message401:
      // === available params ===
      // {{partnerType}}
      'Om deze pagina te bekijken moet je ingelogd zijn. Log in met je {{partnerType}} email en wachtwoord.',
    cta401: 'Log in',
    title404: '404 fout',
    heading404: 'Sorry, we hebben deze pagina niet kunnen vinden',
    message404: 'Kijk of de URL klopt en probeer het opnieuw.',
    cta404: 'Ga terug',
    title500: '500 fout',
    heading500: 'Oeps, er is iets verkeerd gegaan',
    message500:
      'Helaas werkt onze service momenteel niet. We zijn bezig om het probleem op te lossen. Probeer het later opnieuw.',
    cta500: 'Ga terug',
  },
};
