import { IUser } from '../user/IUser';
import { IUserPii } from '../user/IUserPii';

export function getFirstNameLastName(user?: IUser | IUserPii): { firstName: string; lastName: string } {
  if (!user) {
    return { firstName: '', lastName: '' };
  }
  if (!user.firstName && !user.lastName) {
    return { firstName: '', lastName: '' };
  }
  if (user.firstName && !user.lastName) {
    const t = user.firstName.split('.');
    return { firstName: t[0], lastName: t[1] || '' };
  }
  return { firstName: user.firstName!, lastName: user.lastName! };
}
