import { ReactElement, useEffect } from 'react';

import { IAnalyticsPage } from './Analytics';
import { useAnalyticsContext } from './AnalyticsContext';

function AnalyticsContainer({ pageName, pageProperties, children }: IAnalyticsPage): ReactElement | null {
  const { page } = useAnalyticsContext();

  useEffect(() => {
    page(pageName, pageProperties);
  }, [page, pageName, pageProperties]);

  return children || null;
}

export default AnalyticsContainer;
