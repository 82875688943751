import { IGraphQLErrors } from '../api/graphql/AppSyncClient';
import { APIContextActions } from '../api/APIContext';
import i18next from 'i18next';
import { ISettingsResult, PageSetting } from '../api/graphql/queries/getAppSettings';

const killSwitchSettingKey = 'KillSwitch';
const All = 'All';

export interface InitAppSettingsResult {
  siteDisabled: boolean;
}

export interface InitAppSettingsParameters {
  partner: string;
  region: string;
  setCurrentAppSettings: (settings: ISettingsResult) => void;
}

export async function initializeAppSettings(
  apiActions: APIContextActions,
  settings: InitAppSettingsParameters,
): Promise<InitAppSettingsResult> {
  let result: ISettingsResult | undefined = undefined;
  const { partner, region, setCurrentAppSettings } = settings;

  try {
    result = await apiActions.getAppSettings(killSwitchSettingKey, partner, region);

    initializeTranslations(result);

    setCurrentAppSettings!(result);

    return { siteDisabled: isSiteDisabled(result.appSettings.pageSettings) };
  } catch (err) {
    const error = err as IGraphQLErrors;
    if (
      error.graphQLErrors &&
      error.graphQLErrors.length > 0 &&
      error.graphQLErrors.some((e: any) => e.errorType === 'NOT_FOUND')
    ) {
      return { siteDisabled: false };
      // We are not throwing error since no setting is found
    } else {
      throw new Error(`Error encountered in InitializeAppSettings: ${error.message}`);
    }
  }

  function initializeTranslations(result: ISettingsResult | undefined) {
    result?.appSettings.pageSettings?.forEach((pageSetting) => {
      pageSetting?.pageTranslations?.forEach((pageTranslation) => {
        if (pageSetting.page) {
          i18next.addResourceBundle(pageTranslation.language, `killSwitch-${pageSetting.page.toLowerCase()}`, {
            heading: pageTranslation.heading,
            subHeading: pageTranslation.subHeading,
            message: pageTranslation.message,
          });
        }
      });
    });
  }

  function isSiteDisabled(pageSettings: Partial<PageSetting>[]): boolean {
    return pageSettings?.some((p) => p?.page && p?.page.toLowerCase() === All.toLowerCase());
  }
}
