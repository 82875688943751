import { createContext, useContext, useReducer, useEffect, useState } from 'react';
import { ISettingsResult } from '../api/graphql/queries/getAppSettings';
import { useAppState } from '../components/app/useAppState';

import {
  getAllSupportedLanguages,
  getDefaultLanguage,
  getPartnerAndPartnerRegionFromUrl,
} from '../helpers/PartnerRegionPropertySelector';
import { RegionType } from '../helpers/RegionType';
import { Persistence } from '../persistence/Persistence';
import { PartnerType } from '../interfaces/Partner';

export interface ApplicationState {
  currentPartner: PartnerType;
  setCurrentPartner: (currentPartner: PartnerType) => void;
  currentPartnerRegion: RegionType;
  setCurrentPartnerRegion: (currentRegion: RegionType) => void;
  currentLanguage: string;
  setCurrentLanguage: (currentLanguage: string) => void;
  currentLoyaltyType: string;
  setCurrentLoyaltyType: (currentLoyaltyType: string) => void;
  currentLocale: string;
  setCurrentLocale: (currentLoyaltyType: string) => void;
  currentAnalyticsSlug: string;
  setCurrentAnalyticsSlug: (currentAnalyticsSlug: string) => void;
  currentAppSettings: ISettingsResult;
  setCurrentAppSettings: (settings: ISettingsResult) => void;
}

/* istanbul ignore next: No need to codecov*/
const ApplicationContext = createContext<ApplicationState>({
  currentPartner: 'uber-pro',
  /* istanbul ignore next: No need to codecov*/
  setCurrentPartner: (_currentPartner: string) => {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      ('');
    }
  },
  currentPartnerRegion: '',
  /* istanbul ignore next: No need to codecov*/
  setCurrentPartnerRegion: (_currentPartnerRegion: RegionType) => {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      ('');
    }
  },
  currentLanguage: '',
  /* istanbul ignore next: No need to codecov*/
  setCurrentLanguage: (_currentLanguage: string) => {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      ('');
    }
  },
  currentLoyaltyType: '',
  /* istanbul ignore next: No need to codecov*/
  setCurrentLoyaltyType: (_currentLoyaltyType: string) => {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      ('');
    }
  },
  currentLocale: '',
  /* istanbul ignore next: No need to codecov*/
  setCurrentLocale: (_currentLocale: string) => {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      ('');
    }
  },
  currentAnalyticsSlug: '',
  /* istanbul ignore next: No need to codecov*/
  setCurrentAnalyticsSlug: (_currentAnalyticsSlug: string) => {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      ('');
    }
  },
  currentAppSettings: {
    appSettings: {
      pageSettings: [],
    },
  },
  /* istanbul ignore next: No need to codecov*/
  setCurrentAppSettings: (_currentAppSettings: ISettingsResult) => {
    // eslint-disable-next-line no-lone-blocks
    {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      ('');
    }
  },
});

const useApplicationContext = (): ApplicationState => useContext(ApplicationContext);

const appSettingsReducer = (_oldValue: ISettingsResult, newValue: ISettingsResult) => newValue;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ApplicationContextProvider = ({ children }: any): JSX.Element => {
  // NOTE:
  // We set the persistence storage in the handlers instead of a useEffect / useState
  // due to the lifecycle / order of things that happen, this helps the analytics have
  // the right values to send while pages might not be fully render and are redirecting.

  const [currentPartner, setCurrentPartner] = useState(Persistence.getPartner() as PartnerType);
  function handleCurrentPartnerChange(newValue: PartnerType) {
    Persistence.setPartner(newValue);
    setCurrentPartner(newValue);
  }

  const [currentPartnerRegion, setCurrentPartnerRegion] = useState(Persistence.getPartnerRegion() as RegionType);
  function handleCurrentPartnerRegionChange(newValue: RegionType) {
    Persistence.setPartnerRegion(newValue);
    setCurrentPartnerRegion(newValue);
  }

  const [currentAppSettings, setCurrentAppSettings] = useReducer(appSettingsReducer, {
    appSettings: {
      pageSettings: [],
    },
  });

  const [currentLanguage, setCurrentLanguage] = useState(Persistence.getLanguage() as string);
  function handleCurrentLanguageChange(newValue: string) {
    Persistence.setLanguage(newValue);
    setCurrentLanguage(newValue);
  }

  const [currentLoyaltyType, setCurrentLoyaltyType] = useState(Persistence.getLoyaltyType() as string);
  function handleCurrentLoyaltyTypeChange(newValue: string) {
    Persistence.setLoyaltyType(newValue);
    setCurrentLoyaltyType(newValue);
  }

  const [currentLocale, setCurrentLocale] = useState(Persistence.getLocale() as string);
  function handleCurrentLocaleChange(newValue: string) {
    Persistence.setLocale(newValue);
    setCurrentLocale(newValue);
  }

  const [currentAnalyticsSlug, setCurrentAnalyticsSlug] = useState(Persistence.getAnalyticsSlug() as string);
  function handleCurrentAnalyticsSlugChange(newValue: string) {
    Persistence.setAnalyticsSlug(newValue);
    setCurrentAnalyticsSlug(newValue);
  }

  // Use this to set the default application state
  useEffect(() => {
    'this will run once and only once';
    Persistence.setConsentCheckOverrideStatus(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { changeLanguage } = useAppState();

  useEffect(() => {
    if (currentPartner && currentPartnerRegion) {
      changeLanguage(getDefaultLanguage(currentPartner, currentPartnerRegion));
    } else {
      const { partnerFromUrl, partnerRegionFromUrl } = getPartnerAndPartnerRegionFromUrl();
      if (partnerFromUrl && partnerRegionFromUrl) {
        changeLanguage(getDefaultLanguage(partnerFromUrl, partnerRegionFromUrl));
      } else {
        // unsupported region - fallback to user's browser's language
        const supportedLangs = getAllSupportedLanguages();
        const browserLang = window.navigator.language.substring(0, 2);
        const lang = supportedLangs.includes(browserLang) ? browserLang : 'en';
        changeLanguage(lang);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPartner, currentPartnerRegion]);

  return (
    <ApplicationContext.Provider
      value={{
        currentPartner: currentPartner,
        setCurrentPartner: handleCurrentPartnerChange,
        currentPartnerRegion: currentPartnerRegion,
        setCurrentPartnerRegion: handleCurrentPartnerRegionChange,
        currentLanguage: currentLanguage,
        setCurrentLanguage: handleCurrentLanguageChange,
        currentLoyaltyType: currentLoyaltyType,
        setCurrentLoyaltyType: handleCurrentLoyaltyTypeChange,
        currentLocale: currentLocale,
        setCurrentLocale: handleCurrentLocaleChange,
        currentAnalyticsSlug: currentAnalyticsSlug,
        setCurrentAnalyticsSlug: handleCurrentAnalyticsSlugChange,
        currentAppSettings: currentAppSettings,
        setCurrentAppSettings: setCurrentAppSettings,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export { ApplicationContextProvider, useApplicationContext, ApplicationContext };
