import { ReactElement, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { staticConfig } from '../../config';
import { useApplicationContext } from '../../contexts/ApplicationContext';
import { getPartnerAndPartnerRegionFromUrl } from '../../helpers/PartnerRegionPropertySelector';

import { useTheme } from '../../utils/theme/useTheme';
import { generateStyleFromTheme } from '../../utils/theme/helper';

const DocumentHead = (): ReactElement => {
  const { t } = useTranslation('title');

  const { currentPartner, currentPartnerRegion } = useApplicationContext();

  const [partner, setPartner] = useState('');
  const [partnerRegion, setPartnerRegion] = useState('');

  const theme = useTheme();

  const [style, setStyle] = useState('');

  useEffect(() => {
    if (currentPartner && currentPartnerRegion) {
      setPartner(currentPartner);
      setPartnerRegion(currentPartnerRegion);
    } else {
      // fallback for terms and conditions/privacy policy pages
      const { partnerFromUrl, partnerRegionFromUrl } = getPartnerAndPartnerRegionFromUrl();
      if (partnerFromUrl && partnerRegionFromUrl) {
        setPartner(partnerFromUrl);
        setPartnerRegion(partnerRegionFromUrl);
      }
    }
    if (theme) {
      setStyle(generateStyleFromTheme(theme));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  return (
    <Helmet>
      {/*
        For a partner like "uber-pro" and a region like "nl",
        the corresponding translation key needs to be uberproNL.
        Please keep this in mind when adding translations!
      */}
      <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1" data-react-helmet="true" />
      <meta name="robots" content="noindex" />
      <meta name="googlebot" content="noindex" />
      <title>{t(partner.replace('-', '').toLowerCase() + partnerRegion.toUpperCase())}</title>
      <script>{`
          // Config: Firebase
          var firebaseConfig = {
              apiKey: '${staticConfig.firebase.apiKey}',
              authDomain: '${staticConfig.firebase.authDomain}',
              databaseURL: '${staticConfig.firebase.databaseURL}',
              projectId: '${staticConfig.firebase.projectId}',
              storageBucket: '${staticConfig.firebase.storageBucket}',
              messagingSenderId: '${staticConfig.firebase.messagingSenderId}',
              appId: '${staticConfig.firebase.appId}'
          };
          `}</script>
      <script id="performance-reporting-script" src={'/scripts/firebase.js'} async={true}></script>
      <style>{style}</style>
    </Helmet>
  );
};

export default DocumentHead;
