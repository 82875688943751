import { ReactElement } from 'react';

const PrimeHeader = (): ReactElement => {
  return (
    <div data-testid="prime-header" className="primeHeader">
      <div className="earnifyLogo">
        <img alt="logo not found" src={`/images/amazon-prime/fuel/earnify-prime-logo.svg`}></img>
      </div>
    </div>
  );
};

export default PrimeHeader;
