import { ReactElement } from 'react';

const DefaultHeader = (): ReactElement => {
  return (
    <div data-testid="default-header" className="primeHeader">
      <div className="earnifyLogo">
        <img className="bp-logo" alt="logo not found" src={`/images/amazon-prime/fuel/bp.svg`}></img>
      </div>
    </div>
  );
};

export default DefaultHeader;
