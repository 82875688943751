import { useEffect, useState } from 'react';

export const useWindowLocation = (): Location => {
  const [location, setLocation] = useState<Location>(window.location);

  useEffect(() => {
    const setWindowLocation = () => {
      setLocation(window.location);
    };

    if (!location) {
      setWindowLocation();
    }

    window.addEventListener('popstate', setWindowLocation);

    return () => {
      window.removeEventListener('popstate', setWindowLocation);
    };
  }, [location]);

  return location;
};
