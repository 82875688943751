import getAppSettingsQuery from 'graphql-tag';

export interface ISettingsResult {
  appSettings: AppSettings;
}

export interface AppSettings {
  pageSettings: Partial<PageSetting>[];
}

export interface PageSetting {
  page: string;
  pageTranslations: PageTranslation[];
}

export interface PageTranslation {
  language: string;
  subHeading: string;
  heading: string;
  message: string;
}

export default getAppSettingsQuery`
query getAppSettings($settingKey: String!, $partnerType: String!, $partnerRegion: String!) {
  appSettings(settingKey: $settingKey, partnerType: $partnerType, partnerRegion: $partnerRegion) {
    pageSettings {
      page
      pageTranslations {
        heading
        language
        message
        subHeading
      }
    }
  }
}
`;
