import getOffersQuery from 'graphql-tag';

export declare type OfferIdType = 'UberProTier' | 'UberProIntro' | 'UberProPriceMatch';

export interface IOfferInfo {
  offerId: OfferIdType;
  actualOfferValue: number;
  adjustedOfferValue: number;
  offerActive: boolean;
  offerName: string;
  offerType: number;
  offerValueType: string;
}

export interface IOffersResult {
  offers: IOfferInfo[];
}

export default getOffersQuery`
query getOffers($partnerType: String!, $partnerRegion: String!) {
    offers(partnerType: $partnerType, partnerRegion: $partnerRegion) {
        actualOfferValue
        adjustedOfferValue
        offerActive
        offerId
        offerType
        offerValueType
    }
}
`;
