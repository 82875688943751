import { createContext, ReactElement, useContext } from 'react';
import { History } from 'history';
import Analytics, { GAClientUserTraits } from './Analytics';

export interface AnalyticsContextState {
  identify(ga4Traits?: GAClientUserTraits): Promise<void>;
  page(name: string, properties?: any): Promise<void>;
  track(event: string, properties?: any): Promise<void>;
}

export interface AnalyticsContextProviderOptions {
  children?: ReactElement;
  history: History;
}

/* istanbul ignore next: no need to cover this proxy */
const initialState: AnalyticsContextState = {
  identify: async (ga4Traits?: GAClientUserTraits): Promise<void> => {
    return Analytics.identify(ga4Traits);
  },
  page: async (name: string, properties?: any): Promise<void> => {
    return Analytics.page(null, name, properties);
  },
  track: async (event: string, properties?: any): Promise<void> => {
    return Analytics.track(null, event, properties);
  },
};

export const AnalyticsContext = createContext<AnalyticsContextState>(initialState);

export const useAnalyticsContext = (): AnalyticsContextState => useContext(AnalyticsContext);
