import patchMappingsMutation from 'graphql-tag';

export default patchMappingsMutation`
mutation patchMappings($patchMappingsInput: PatchMappingsInput!){
    patchMappings(patchMappingsInput: $patchMappingsInput)
  }
`;

export type PatchMappingsInput = {
  partnerType: string;
  loyaltyType: string;
  preExistingIdpRecord: boolean;
};
