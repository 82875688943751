import { ReactElement, MouseEvent } from 'react';

import { getPartnerRegionConfig, getSupportedRegions } from '../../helpers/PartnerRegionPropertySelector';
import { IAppCallBackProps } from '../../routing/RouterComponentSwitcher';

export enum SupportedRegionListMode {
  PrivacyPolicy,
  TermsAndConditions,
}

interface IPartnerRegionsSelector extends IAppCallBackProps {
  mode: SupportedRegionListMode;
  partner: string;
  onViewLinkForRegion: (path: string, region: string) => void;
}

const PartnerRegionsSelector = (props: IPartnerRegionsSelector): ReactElement => {
  const supportedRegions = getSupportedRegions(props.partner);

  const _getLink = (region: string): string => {
    const _partner = props.partner.length > 0 ? props.partner : '[partner not set]';
    const _region = region.length > 0 ? region : '[region not set]';
    const _page = props.mode === SupportedRegionListMode.PrivacyPolicy ? 'privacy' : 'terms';
    return '/' + _partner + '/' + _region + '/' + _page + '/latest';
  };

  const _handleViewLink = (region: string, e?: MouseEvent) => {
    e?.preventDefault();
    const link = _getLink(region);
    props.onViewLinkForRegion(link, region);
  };

  return (
    <ul className="region-selector" data-testid="region-selector">
      {supportedRegions.map((region) => (
        // eslint-disable-next-line react/jsx-key
        <li
          key={
            (props.mode === SupportedRegionListMode.PrivacyPolicy ? 'privacy' : 'terms') +
            '-region-link-' +
            region.toLowerCase()
          }
        >
          <a
            data-testid={
              (props.mode === SupportedRegionListMode.PrivacyPolicy ? 'privacy' : 'terms') +
              '-region-link-' +
              region.toLowerCase()
            }
            onClick={(e) => _handleViewLink(region, e)}
            href={_getLink(region)}
          >
            {getPartnerRegionConfig(props.partner, region).regionDisplayName}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default PartnerRegionsSelector;
