import AWSAppSyncClient from 'aws-appsync';

export interface IBFFClientResult {
  data?: any;
  errors?: any;
}

export interface IGraphQLErrors {
  graphQLErrors?: any;
  networkError?: any;
  message?: any;
  data?: any;
}

export interface IBFFClient {
  addHeader(key: string, value: string): void;
  fetch<T>(query: any): Promise<T>;
  post(query: any, variables: any): Promise<IBFFClientResult>;
}

interface AppSyncClientOptions {
  url: string;
  region: string;
}

export interface AuthType {
  idToken: string;
  apiKey: string;
  accessToken?: string;
}

export class AppSyncClient implements IBFFClient {
  private client?: AWSAppSyncClient<any>;
  private headers: Map<string, string> = new Map();

  constructor(authType: AppSyncClientOptions & Partial<AuthType>) {
    this.configure(authType);
  }

  private configure(authType: AppSyncClientOptions & Partial<AuthType>): void {
    const { idToken, apiKey } = authType;
    if (!idToken && !apiKey) {
      throw new Error('Please specify an Id Token or an API Key');
    }
    this.client = new AWSAppSyncClient({
      url: authType.url,
      region: authType.region,
      disableOffline: true,
      auth: idToken
        ? {
            type: 'OPENID_CONNECT',
            jwtToken: idToken!,
          }
        : {
            type: 'API_KEY',
            apiKey: apiKey!,
          },
    });
  }

  private strMapToObj(strMap: any) {
    const obj = Object.create({});
    for (const [k, v] of strMap) {
      obj[k] = decodeURI(v);
    }
    return obj;
  }
  private strMapToJson(strMap: Map<string, string>) {
    return this.strMapToObj(strMap);
  }

  private getHeaders(): any {
    const obj = this.strMapToJson(this.headers);
    return obj;
  }

  addHeader(key: string, value: string): void {
    this.headers.set(key, value);
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  fetch<T>(query: any, requiredVariables?: any): Promise<T> {
    return new Promise<any>((resolve, reject) => {
      if (this.client) {
        this.client
          .query<T>({
            query: query,
            variables: requiredVariables,
            context: {
              headers: this.getHeaders(),
            },
          })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  post(requiredMutation: any, requiredVariables: any): Promise<IBFFClientResult> {
    return new Promise<IBFFClientResult>((resolve, reject) => {
      const options = {
        mutation: requiredMutation,
        variables: requiredVariables,
        context: {
          headers: this.getHeaders(),
        },
      };

      if (this.client) {
        this.client
          ?.mutate(options)
          .then((response) => {
            resolve(response as IBFFClientResult);
          })
          .catch((error) => {
            reject(error as IBFFClientResult);
          });
      }
    });
  }
}
