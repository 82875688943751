import fuelDev from '../configs/uber-pro/fuel/partners/dev';
import fuelLocal from '../configs/uber-pro/fuel/partners/local';
import fuelTest from '../configs/uber-pro/fuel/partners/test';
import fuelProd from '../configs/uber-pro/fuel/partners/prod';

import { Partner } from '../interfaces/Partner';

/**
 * Development = DEV environment
 * Testing / Local = For unit test/UI test
 * Staging = TEST environment
 * Production = PROD environment
 */
export function getPartnerData(environment: string): Partner[] {
  const partners: Record<string, Partner[]> = {
    development: fuelDev,
    staging: fuelTest,
    production: fuelProd,
  };

  return partners[environment] || fuelLocal;
}
