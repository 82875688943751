import Analytics from '../../analytics/Analytics';
import { FlowOptions, FlowStepActionFunctionVariadic } from '../flow-engine';
import history from '../../routing/BrowserHistory';
import { APIContextActions } from '../../api/APIContext';

export const action: FlowStepActionFunctionVariadic = function action(
  _apiContext: APIContextActions,
  flowOptions: Partial<FlowOptions>,
): void {
  const { currentPartner, currentPartnerRegion, suggestedAuthProvider } = flowOptions;

  const traits =
    currentPartner && currentPartnerRegion && suggestedAuthProvider
      ? {
          partner: currentPartner,
          partnerRegion: currentPartnerRegion,
          authProvider: suggestedAuthProvider,
        }
      : undefined;
  Analytics.identify(traits);
  history.replace('/prime-consent');
};
