import LoginAndConfigureRedirector from './LoginAndConfigureRedirector';
import { Config, TokenManager } from '@forgerock/javascript-sdk';
import { getPartnerRegionConfig, getMiddlewareConfig } from '../helpers/PartnerRegionPropertySelector';
import { useOAuthContext } from '../auth/OAuthContext';

interface ICipLoginRedirectorProps {
  partner: string;
  partnerRegion: string;
}

export const CipLoginRedirector = ({ partner, partnerRegion }: ICipLoginRedirectorProps) => {
  const { cipConfig } = getPartnerRegionConfig(partner, partnerRegion);
  const { idToken, accessToken } = useOAuthContext();

  let cipConfigWithAcr = JSON.parse(JSON.stringify(cipConfig));

  if (cipConfig) {
    cipConfigWithAcr = getMiddlewareConfig(partner, cipConfigWithAcr);
  }

  if (!cipConfig) {
    throw new Error(`CIP config not found for ${partner} and ${partnerRegion}.`);
  }

  const processHandleLogin = async () => {
    if (!idToken && !accessToken) {
      Config.set(cipConfigWithAcr);
      // Force refesh CIP tokens
      await TokenManager.getTokens({
        forceRenew: true,
        login: 'redirect',
      });
    }
  };

  return (
    <LoginAndConfigureRedirector
      key={`initiate-oauth-web-server-flow-${partner}-${partnerRegion}`}
      handleLogin={processHandleLogin}
      partner={partner}
      pageName={'Login'}
      partnerRegion={partnerRegion}
    />
  );
};
