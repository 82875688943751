export function redirectToPartnerPage(partnerType: string, useHistory: any) {
  switch (partnerType) {
    case 'amazon-prime':
      useHistory.push(`/success`);
      break;
    case 'uber-pro':
      useHistory.push(`/home`);
      break;
    default:
      useHistory.push(`/500`);
      break;
  }
}
