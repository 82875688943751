import partnerLinkingMutation from 'graphql-tag';

export default partnerLinkingMutation`
mutation partnerLinking($partnerInformation: PartnerInformation!) {
    partnerLinking(partnerInformation: $partnerInformation ) 
  }
`;

export type PartnerInformation = {
  benefitStatus: string;
  linkChannel: string;
  partnerType: string;
  partnerRegion: string;
  userId: string;
};
