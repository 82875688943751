import { ReactElement, useEffect, useRef } from 'react';

import { useAPIContext } from '../api/APIContext';
import ActivityIndicator, { ActivityIndicatorType } from '../components/ActivityIndicator/ActivityIndicator';
import { useAppState } from '../components/app/useAppState';
import { useAnalyticsContext } from '../analytics/AnalyticsContext';
import { useApplicationContext } from '../contexts/ApplicationContext';
import { useSuggestedAuthProvider } from '../hooks/useSuggestedAuthProvider';
import { getDefaultLanguage, getLoyaltyType } from '../helpers/PartnerRegionPropertySelector';
import getLocale, { LocaleType } from '../helpers/Locale';
import { RegionType } from '../helpers/RegionType';
import { initializeAppSettings } from '../helpers/InitializeAppSettings';
import { PartnerType } from '../interfaces/Partner';
import { getMarvelLogger } from '@marvel-common/bp-digital-logging';

export interface IExternalRedirectProps {
  pageName: string;
  pageProperties?: any;
  partner: string;
  partnerRegion: string;
  handleLogin: () => Promise<void>;
}

const LoginAndConfigureRedirector = (props: IExternalRedirectProps): ReactElement => {
  const apiContext = useAPIContext();
  const ref = useRef(false);

  const {
    setCurrentPartner,
    setCurrentPartnerRegion,
    setCurrentLanguage,
    setCurrentLoyaltyType,
    setCurrentLocale,
    setCurrentAnalyticsSlug,
    setCurrentAppSettings,
  } = useApplicationContext();
  const authProvider = useSuggestedAuthProvider();

  const { setShowFooter } = useAppState();
  const { page, identify } = useAnalyticsContext();

  const getSlugQueryParam = (): string => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const slug = params.get('slug')?.toLowerCase();
    if (slug && slug?.length > 0) return slug;
    return '';
  };

  // we handle the page view in this component so we can wait
  // for the async request to complete
  const redirectHandler = async (slug: string): Promise<void> => {
    const properties: any = props.pageProperties || {};
    if (properties.partner === undefined && props.partner !== undefined) {
      properties.partner = props.partner;
    }

    const traits = { slug, partner: props.partner, partnerRegion: props.partnerRegion, authProvider };

    // set and execute promises
    const promises = [page(props.pageName, properties), identify(traits)];
    Promise.all(promises); // don't wait for this to complete before redirect
    // perform the redirect

    await props.handleLogin();
  };

  useEffect(() => {
    (async () => {
      if (ref.current) {
        return;
      }
      ref.current = true;

      const logger = getMarvelLogger('init');
      logger.info('Redirecting to: ', props);
      setShowFooter(false);
      const partner = props.partner as PartnerType;
      const partnerRegion = props.partnerRegion as string;
      setCurrentPartner(partner);
      const parsedLocale: LocaleType = getLocale(partnerRegion);
      setCurrentPartnerRegion(partnerRegion as RegionType);
      setCurrentLocale(parsedLocale.locale as string);
      setCurrentLanguage(getDefaultLanguage(partner, partnerRegion));
      setCurrentLoyaltyType(getLoyaltyType(partner, partnerRegion));
      const slug = getSlugQueryParam();
      setCurrentAnalyticsSlug(slug);
      const { siteDisabled } = await initializeAppSettings(apiContext, {
        partner: props.partner,
        region: props.partnerRegion as RegionType,
        setCurrentAppSettings: setCurrentAppSettings,
      });

      if (siteDisabled) {
        return; // Do not proceed further. Terminate here.
      }
      redirectHandler(slug);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return <ActivityIndicator type={ActivityIndicatorType.FullPage} />;
};

export default LoginAndConfigureRedirector;
